import React, { useState } from "react"
import "../../assets/scss/manual/manual.scss";
import { Card, CardBody, Col, Row, Button, Spinner } from "reactstrap"
import AddUser from "./addNewUser";
import ButtonDropdownClient from "./ButtonDropdown";
import { commaSeperatedNumberWithoutDecimal } from "helpers/stringHelper";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import EditUser from "./editUser";
import DeleteUser from "./deleteUser";
import { Link } from "react-router-dom";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const Users = (prop) => {
    const {usersData, getUsers, id, accountData, loading} = prop
    const [addUserOpen, setAddUserOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [loadingUsers, setLoadingUsers] = useState(false)

    const handleStatusChange = async(e, user) =>{
      setLoadingUsers(true)
      try {
        const {data} = await axios.post(`${AppConfig.baseUrl}/users/change_status`, {id: user._id, is_active: e.target.value},{
          headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
          toast.success("User status changed successfully");
          getUsers()
        }
        else{
          throw new Error(data.title);
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong');
      }
      setLoadingUsers(false)
    }

    const toggleEdit = () => {
      setEditOpen(!editOpen);
    }
    
    const toggleDelete = () => {
      setDeleteOpen(!deleteOpen);
    }

    const columns = [
        {
          name: "name",
          label: "Name",
          options : {
            setCellProps: () => ({style: {minWidth: '200px', height: '70px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            customBodyRenderLite: (dataIndex) => {
              return <>
                {usersData && usersData[dataIndex] && !usersData[dataIndex].profile_picture ? (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="avatar-sm">
                      <div className="avatar-title rounded-circle">
                        {usersData && usersData[dataIndex] && usersData[dataIndex].name.charAt(0)}
                      </div>
                    </div>
                    <div className="client">
                      <div>{usersData && usersData[dataIndex] && usersData[dataIndex].name}</div>
                    </div>
                  </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className="avatar-sm">
                        <img
                          className="rounded-circle avatar-sm"
                          src={usersData && usersData[dataIndex] && usersData[dataIndex].profile_picture}
                          alt=""
                        />
                      </div>
                      <div className="client">
                        <div>{usersData && usersData[dataIndex] && usersData[dataIndex].name}</div>
                      </div>
                    </div>
                  )}
              </>
            }
          }
        },
        {
          name: "email",
          label: "Email",
          options : {
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender: (user) => (<div className="table-data-margin">{user || 'NA'}</div>)
          }
        },
        {
          name: "type",
          label: "Role",
          options : {
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender: (user) => (<div className="table-data-margin">{`${user && user === 'user' ? `${accountData && accountData.customer_id && accountData.customer_id.company ? accountData.customer_id.company.toUpperCase() : ''} `: ''}${user ? user.replace("_", " ").toUpperCase() : 'NA'}` || 'NA'}</div>)
          }
        },
        {
          name: "no_of_clients",
          label: "No of clients ",
          options : {
            customBodyRender: (user) => (<div className="table-data-margin">{user ? commaSeperatedNumberWithoutDecimal(user) : 0}</div>)
          }
        },
        {
          name: "status",
          label: "Status",
          // eslint-disable-next-line react/display-name
          options : {
            customBodyRenderLite: (dataIndex) => (
              <div className="table-data-margin">
                {
                  usersData && usersData[dataIndex] && usersData[dataIndex].email == (accountData && accountData.customer_id ? accountData.customer_id.email : '') ?
                  <div className={`text-${usersData && usersData[dataIndex] && usersData[dataIndex].is_active ? 'success' : 'warning'} ms-1`}
                    style={{ border: 'none', width: 'auto', fontWeight: 'bold' }}
                  >
                    {usersData && usersData[dataIndex] && usersData[dataIndex].is_active ? 'ACTIVE' : 'DISABLED'}
                  </div>
                  :
                  <select
                    id="formrow-InputState"
                    className={`drp text-${usersData && usersData[dataIndex] && usersData[dataIndex].is_active ? 'success' : 'warning'}`}
                    style={{ border: 'none', width: 'auto', fontWeight: 'bold' }}
                    onChange={(e) => handleStatusChange(e, usersData && usersData[dataIndex])}
                    disabled={loadingUsers}
                  >
                    <option name="active" value={true} selected={usersData && usersData[dataIndex] && usersData[dataIndex].is_active}>ACTIVE</option>
                    <option name="disable" value={false} selected={usersData && usersData[dataIndex] && !usersData[dataIndex].is_active}>DISABLED</option>
                  </select>
                }
              </div>
            )
          }
        },
        {
          name: "last_login",
          label: "Last logged in",
          options : {
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender: (user) => (<div className="table-data-margin">{user ? moment(user).format('YYYY-MM-DD HH:mm') : ''}</div>)
          }
        },
        {
          name: "last_logout",
          label: "Last logged out",
          options : {
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender: (user) => (<div className="table-data-margin">{user ? moment(user).format('YYYY-MM-DD HH:mm') : ''}</div>)
          }
        },
        {
          name: "last_ip_address",
          label: "Last IP address",
          options : {
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRender: (user) => (<div className="table-data-margin">{user ? user : ''}</div>)
          }
        },
        {
          name: "menu",
          label: "Action",
          // eslint-disable-next-line react/display-name
          options : {
            customBodyRenderLite: (dataIndex) => (
              <div className="mt-2">
                {
                  usersData && usersData[dataIndex] && usersData[dataIndex].email == (accountData && accountData.customer_id ? accountData.customer_id.email : '') ? 
                    <Link to={{pathname:`/accounts/users/details`, search: usersData && usersData[dataIndex] && usersData[dataIndex]._id}}><Button  className="btn btn-sm bg-theme-color">View</Button></Link>
                  :
                  <ButtonDropdownClient setEditOpen={setEditOpen} setDeleteOpen={setDeleteOpen} user={usersData && usersData[dataIndex]} setSelectedUser={setSelectedUser}/>
                }
              </div>
            )
          }
        },
      ]

    const toggleAddUser = () => {
        setAddUserOpen(!addUserOpen);
    }
  
    return (
        <>
            <Card>
                <CardBody>
                  <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                  MUIDataTableToolbar:{
                      styleOverrides:{
                      actions: {
                          display: 'contents'
                      }
                      }
                  }
                  }})}>
                  <MUIDataTable
                      title={
                      <Row>
                        <Col className="mt-2">USERS</Col>
                        <Col>
                        <div>
                          <button
                            type="button"
                            className="btn bg-theme-color dataTables_filter"
                            onClick={toggleAddUser}
                            disabled={accountData && accountData.customer_id && accountData.customer_id.is_deleted === true}
                          >
                            ADD NEW
                          </button>
                        </div>
                        </Col>
                      </Row>
                      }
                      data={usersData}
                      columns={columns}
                      options={{ 
                      rowsPerPage:10, 
                      print:false, 
                      download:false, 
                      filter:false, 
                      viewColumns:false,
                      pagination : false,
                      selectableRows:"none", 
                      search:false, 
                      responsive:'scroll',
                      rowsPerPageOptions:[], 
                      responsive:'scroll',
                        textLabels: {
                          body: {
                            noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                          },
                          viewColumns: {
                            title: "",
                          },
                      },
                      setRowProps: (row,dataIndex, rowIndex) =>{
                        return {
                          style: {
                            backgroundColor:
                            usersData[dataIndex].email == (accountData && accountData.customer_id && accountData.customer_id.email) ? 'rgb(238, 237, 237)' : "",
                          },
                        };
                      }
                      }}
                  />
                  </ThemeProvider>
                </CardBody>
            </Card>
            <AddUser addOpen={addUserOpen} toggleAdd={toggleAddUser} setOpenPopup={setAddUserOpen} id={id} getUsers={getUsers} accountData={accountData} />
            <EditUser editOpen={editOpen} toggleEdit={toggleEdit} setEditOpen={setEditOpen} user={selectedUser} getUserData={getUsers} setSelectedUser={setSelectedUser} accountData={accountData} />
            <DeleteUser deleteOpen={deleteOpen} toggleDelete={toggleDelete} setDeleteOpen={setDeleteOpen} user={selectedUser} getUserData={getUsers} setSelectedUser={setSelectedUser} />
        </>
    )
}

export default Users