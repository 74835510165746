import React,{ useState} from "react"
import {Modal} from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";

const Delete = (prop) => {
    const { deleteUser, togglePermanentDelete, setDeleteUser, user, getArchievData, setSelectedUser} = prop;
    const [loading, setLoading] = useState(false);

    const removeAccount = async() => {
        setLoading(true);
        try {
            const {data} = await axios.post(`${AppConfig.baseUrl}/customer/delete_permanent`, {id: user.customer_id._id},{
              headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                toast.success('Account delete permanently');
                getArchievData()
                handleClosePopup()
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false);
    }

    const handleClosePopup = () => {
        setDeleteUser(false);
        setSelectedUser({})
    }

    return (
        <Modal
            isOpen={deleteUser}
            toggle={() => {
                togglePermanentDelete();
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Delete Account</h5>
                <button
                    type="button"
                    onClick={() => {
                        handleClosePopup()
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form>
                    <div className="mb-3">
                        Are you sure you want to delete this account permanently?
                    </div>
                    <div className="modal-footer mt-0">
                        <button
                            type="button"
                            className="btn bg-theme-color"
                            onClick={() =>
                                handleClosePopup()
                            }
                            disabled={loading}
                        >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            No
                        </button>
                        <button type="button" className="btn bg-theme-color" onClick={removeAccount} disabled={loading}>
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            Yes
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default Delete