import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, Button } from "reactstrap"

const Breadcrumb = (prop) => {
  return (
    prop.breadcrumbItem == 'Account Details' ? 
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{prop.breadcrumbItem}</h4>
          <Link to={{pathname:'/accounts/billing', search: prop.account_id}} className="text-white">
          <Button className="btn bg-theme-color text-white" style={{marginTop: '10px'}}>
            BILLING
          </Button>
        </Link>
        </div>
      </Col>      
    </Row>
    :
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{prop.breadcrumbItem}</h4>
        </div>
      </Col>
    </Row>
  )
}

export default Breadcrumb
