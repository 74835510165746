import React, { useEffect, useState } from "react"
import {useLocation } from "react-router-dom"
import { Card, CardBody, Button, Col, Container, Row, Spinner, InputGroup , ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios";
import AppConfig from "constants/config";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import Layout from "components/VerticalLayout";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const AccountDetails = () => {
    const location = useLocation();
    const [id, setId] = useState('');
    const [startDate, setStartDate] = useState(moment().endOf('M'));
    const [alltotal, setAllTotal] = useState(0)
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false)
    const [leaseSummary,setLeaseSummary] = useState([])
    const [billingSummary,setBillingSummary] = useState('')
    const [viewBtn, setViewBtn] = useState(false);
    const columns = [
      {
        name: "client_name",
        label: "Client Name",
        options:{
            search: false,
            customBodyRender: (lease) => (
              <div className="table-data-margin">
                <div>{lease}</div>
              </div>
            ),
        }
      },
      {
        name: "active",
        label: "Active",
        options:{
            search: false,
            customBodyRender: (lease) => (
              <div className="table-data-margin">
                <div>{lease}</div>
              </div>
            ),
        }
      },
      {
        name: "terminated",
        label: "Terminated",
        options:{
            search: false,
            customBodyRender: (lease) => (
              <div className="table-data-margin">
                <div>{lease}</div>
              </div>
            ),
        }
      },
      {
        name: "expired",
        label: "Expired",
        options:{
            search: false,
            customBodyRender: (lease) => (
              <div className="table-data-margin">
                <div>{lease}</div>
              </div>
            ),
        }
      },
      {
        name: "drafted",
        label: "Drafted",
        options:{
            search: false,
            customBodyRender: (lease) => (
              <div className="table-data-margin">
                <div>{lease}</div>
              </div>
            ),
        }
      },
      {
        name: "total",
        label: "Total",
        options:{
            search: false,
            customBodyRender: (lease) => (
              <div className="table-data-margin">
                <div>{lease}</div>
              </div>
            ),
        }
      },
  ]

    useEffect(() => {
        setId((location.search).replace('?', ''));
    }, [location])

    useEffect(() => {
        if(id){
            getCustomerData()
        }
    }, [id,startDate])

    const getCustomerData = async() => {
        setLoading(true)
        try{
            const {data} = await axios.get(`${AppConfig.baseUrl}/lease_summary?id=${id}&date=${startDate ? moment(startDate).endOf('M').format('YYYY-MM-DD') : moment().endOf('M').format('YYYY-MM-DD')}`,{
            headers: { token: localStorage.getItem("token") }
            });
            if(data && !data.error){
                setLeaseSummary(data && data.billingData && data.billingData.data ? data.billingData.data : []);
                setAllTotal(data && data.billingData && data.billingData.overall_total ? data.billingData.overall_total : 0);
                setBillingSummary(data && data.billData ? data.billData : '')
            }else{
                throw new Error(data.title);
            }
        }
        catch(error){
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }
    
    const downloadInvoice = async(url, type) => {
      setDownloading(true);
      try {
          if(url){
              const link = document.createElement('a');
              link.href = url;
              link.download = true
              link.target = "_blank"
              document.body.appendChild(link);
              link.click();
              toast.success(`${type} downloaded successfully`);
          }
          else{
              toast.error('Invoice not found');
          }
      } catch (error) {
          toast.error(error.message || 'Something went wrong');
      }
      setDownloading(false)
    }

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Lease Summary</title>
                </Helmet>
                <Container fluid>
                    <Breadcrumbs title="clientdetail" breadcrumbItem="Lease summary" />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
                                            <MUIDataTable
                                                title={
                                                    <Row>
                                                        <Col>
                                                            <InputGroup className="mb-3">
                                                                <Flatpickr
                                                                    onClose={([date]) => {
                                                                        if(date){
                                                                            setStartDate(date);
                                                                        }
                                                                        else{
                                                                            setStartDate(moment().endOf('M'));
                                                                        }
                                                                    }}
                                                                    value={startDate ? moment(startDate).format('MMMM YYYY') : ''}
                                                                    className="form-control d-block bg-white"
                                                                    placeholder="Start month & year"
                                                                    options={{
                                                                        plugins: [
                                                                            new monthSelectPlugin({
                                                                                shorthand: true,
                                                                                dateFormat: "F Y",
                                                                                altFormat: "F Y", 
                                                                            })
                                                                        ],
                                                                        disable:[(date) => moment(date).isAfter(moment())]
                                                                    }}
                                                                />
                                                                <div className="input-group-append">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                        disabled
                                                                    >
                                                                        <i
                                                                            className="fa fa-calendar"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </InputGroup>
                                                        </Col>
                                                        <Col md="6" style={{paddingRight:'unset'}}>
                                                            <div style={{float:'right', marginRight:'-10px'}}>
                                                            {billingSummary &&
                                                            <>{
                                                                billingSummary && billingSummary.receipt_url ? 
                                                                  <div className="btn-group dropstart">
                                                                    <ButtonDropdown
                                                                      isOpen={viewBtn}
                                                                      toggle={() => { setViewBtn(!viewBtn) }}
                                                                    >
                                                                      <DropdownToggle
                                                                        caret
                                                                        color="lbsuperadmin"
                                                                        className="btn btn-primary me-2"
                                                                      >
                                                                        Download{" "}
                                                                        <i className="mdi mdi-chevron-down" />
                                                                      </DropdownToggle>
                                                                      <DropdownMenu right>
                                                                        <DropdownItem onClick={() => downloadInvoice(billingSummary && billingSummary.invoice_url, "Invoice")}> Invoice</DropdownItem>
                                                                        <DropdownItem onClick={() => downloadInvoice(billingSummary && billingSummary.receipt_url, "Receipt")}> Receipt</DropdownItem>
                                                                      </DropdownMenu>
                                                                    </ButtonDropdown>
                                                                  </div>
                                                                :
                                                                  <Button onClick={() => downloadInvoice(billingSummary && billingSummary.invoice_url, "Invoice")} disabled={downloading} className="btn bg-theme-color text-white me-2" style={{marginTop:"-5px"}}>
                                                                      Download
                                                                  </Button>
                                                              }</>
                                                              }&nbsp;&nbsp;
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                                data={leaseSummary}
                                                columns={columns}
                                                options={{ 
                                                rowsPerPage:10, 
                                                print:false, 
                                                download:true, 
                                                filter:false, 
                                                pagination : false,
                                                viewColumns:false,
                                                selectableRows:"none", 
                                                search:false, 
                                                rowsPerPageOptions:[], 
                                                responsive:'scroll',
                                                textLabels: {
                                                    body: {
                                                      noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                                    },
                                                    viewColumns: {
                                                    title: "",
                                                    },
                                                },
                                                downloadOptions:{
                                                  filename:'billing_summary.csv'
                                                },
                                                customTableBodyFooterRender: () => {
                                                  return (
                                                    <TableFooter style={{position: 'sticky', backgroundColor: '#edf0f5'}}>
                                                      <TableRow>
                                                        <TableCell style={{paddingTop: "13px", paddingBottom: "13px"}}>Total Leases</TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell>{alltotal ? alltotal : 0}</TableCell>
                                                        </TableRow>
                                                    </TableFooter>
                                                  );
                                                }
                                                }}
                                            />
                                            </ThemeProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>                    
                </Container>
            </div>
        </Layout>
    )
}


export default AccountDetails