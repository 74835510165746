import React,{useEffect, useState} from "react";
import makeAnimated from "react-select/animated";
import MySelect from "./MySelect.js";
import {components} from 'react-select'
    
const Option = (prop) => {
    return(
        <div>
        <components.Option {...prop}>
            <input
            type="checkbox"
            checked={prop.isSelected}
            onChange={() => null}
            />{" "}
            <label>{prop.data.label}</label>
        </components.Option>
        </div>
    );
};
    
    
const MultiValue = prop => (
    <components.MultiValue {...prop}>
    <span>{prop.data.label}</span>
    </components.MultiValue>
);
    
const animatedComponents = makeAnimated();
    
const MultiSelect = (prop) =>{
    const {options, handleChange, selectedOptions, loading, placeholder} = prop;
    const [uniqueOptions, setUniqueOptions] = useState([]);

    useEffect(() => {
        const uniqopts = [...new Map(options.map(item =>
            [item.label, item])).values()];

            setUniqueOptions(uniqopts)
    }, [options])

    return (
        <span
            data-toggle="popover"
            data-trigger="focus"
            data-content="Please select account(s)"
        >
        <MySelect
            options={uniqueOptions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                Option,MultiValue,animatedComponents
            }}
            loading={loading}
            onChange={handleChange}
            allowSelectAll={true}
            placeholder={placeholder}
            value={selectedOptions}
        />
        </span>
    )
}

export default MultiSelect;
