import React, { useEffect, useState } from "react"
import { Row, Col, Modal, Label } from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import { useFormik } from "formik";

const AddUserToClient = (prop) => {
    const { addOpen, toggleAdd, setOpenPopup, user, getUserDetails, accountData } = prop
    const [clients, setClient] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        const getAvailableClients = async() => {
            try {
                const {data} = await axios.get(`${AppConfig.baseUrl}/users/available_clients?user_id=${user._id}&customer_id=${accountData && accountData._id ? accountData._id : ''}`,{
                  headers: { token: localStorage.getItem("token") }
                });
                if(!data.error){
                    setClient(data.availableClients)
                }
                else{
                  throw new Error(data.title);
                }
            } catch (error) {
                toast.error(error.message || 'Something went wrong');
            }
        }
        if(user && user._id && accountData && accountData._id){
            getAvailableClients();
        }
    },[user, accountData])
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            client_id: "",
            access_type: "read_only",
            can_review:false
        },
        onSubmit: async (values) => {
            setIsSubmitting(true)
            try {
                if(!formik.values.client_id){ 
                    setIsSubmitting(false)
                    return formik.setFieldError("client_id", "Client name is required");
                }
                values.clients = [{_id:formik.values.client_id,access_type: formik.values.access_type, can_review: formik.values.can_review}]
                values.user_id = user._id;
                const { data } = await axios.post( `${AppConfig.baseUrl}/user_client/add_user_client`, values, {
                    headers: { token: localStorage.getItem("token")},
                });
                if (!data.error) {
                    toast.success("Client added to user successfully");
                    getUserDetails(user._id)
                } else {
                    throw new Error(data.title)
                }
                formik.resetForm()
                setOpenPopup(false);
            } catch (error) {
                toast.error(error.message || "Something went wrong")
            }
            setIsSubmitting(false)
        },
    })

    return (
        <Modal
            isOpen={addOpen}
            toggle={() => {
                toggleAdd();
            }}
            size="md"
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">{`Add a client to ${user.name}`}</h5>
                <button
                    type="button"
                    onClick={() => {
                        setOpenPopup(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12}>
                            <div className="mt-0 mb-0">
                                <Label for="client" className="col-form-label">
                                    Client Name:
                                </Label>
                                <select
                                    className="form-control arrows" 
                                    id="client" 
                                    type='select' 
                                    name="client_id"
                                    onChange={formik.handleChange}
                                    onBlur={() => formik.setFieldTouched("client_id")}
                                >
                                    <option key="select_user" value="">Please select client</option>
                                    {
                                        clients.map(val => {
                                            return (<option key={val._id} value={val._id}>{val.client}</option>)
                                        })
                                    }
                                </select>
                                {formik.touched.client_id && formik.errors.client_id && (
                                    <div className="text-danger">{formik.errors.client_id}</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} xl={12} xxl={12}>
                            <div className="mt-3">
                                <label className="col-form-label">Access Type:</label>
                                <select
                                    className="form-control arrows" 
                                    type='select' 
                                    name="access_type"
                                    onChange={(e) => {
                                        formik.handleChange(e)
                                    }}
                                    onBlur={() => formik.setFieldTouched("access_type")}
                                >
                                    <option name="read_only" value="read_only">Read Only</option>
                                    <option name="full_acess" value="full_access">Full Access</option>
                                </select>
                            </div>
                        </Col>
                        {formik.values.access_type === "full_access" && <Col lg={12} md={12} xl={12} xxl={12}>
                        <div className="d-flex mb-0">
                                <div className="mt-3">
                                    <Label for="can_review" className="col-form-label">
                                        Can review lease
                                    </Label>
                                </div>
                                <div className="form-check form-switch mt-4 ms-3">
                                    <input
                                        type="checkbox"
                                        className="form-check-input switch-icon-color"
                                        id="can_review"
                                        name="can_review"
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                        }}
                                    />
                                    <label
                                        className="form-check-label "
                                        htmlFor="can_review"
                                    />
                                </div>
                            </div>
                        </Col>}
                    </Row>
                    <div className="modal-footer mt-3">
                        <button type="submit" className="btn btn-lbusers" disabled={isSubmitting} >
                            {
                                isSubmitting && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            SUBMIT
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default AddUserToClient