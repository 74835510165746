import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import AppConfig from "constants/config";
import axios from 'axios';
import {Card,CardBody,Col,Container,Row,Spinner} from "reactstrap"
import Breadcrumbs from "../../components/Common/BreadcrumbAdd"
import moment from "moment";
import "assets/scss/manual/manual.scss"
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";

const getActivity = (d)=>{
  if(d == "login"){ return "Logged In"}
  else if(d == 'logout'){ return "Logged Out"}
}

const ActivityLog = () => {
  const params = useParams();
  const [activityLogData, setActivityLogData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [columns,setColumns] = useState([{}]);

  const activitylogColumns = [
    {
      name: "activity",
      label: "Narration",
      options:{ 
        setCellProps: () => ({style: {minWidth: '300px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '300px'}}),
        customBodyRender : (user) => {
          return <>{getActivity(user)}</>
        }
    }
    },
    {
      name: "last_ip_address",
      label: "IP address",
      options:{ 
        setCellProps: () => ({style: {minWidth: '300px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '300px'}}),
        customBodyRender : (user) => ( user )
      }
    },
    {
      name: "createdAt",
      label: "Date Of Activity",
      options:{ 
        setCellProps: () => ({style: {minWidth: '100px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        customBodyRender : (user) => (
          moment(user).format('LLLL')
        )
      }
    }
  ]


  useEffect(()=>{
    getActivitys();
    getUserData();
  },[])

  const getActivitys = async () => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${AppConfig.baseUrl}/user_activity/list_activity?user_id=${params.id}`, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        setActivityLogData(data.data)
        setColumns(activitylogColumns)
      } else {
        throw new Error(data.title);
      }
    } catch (error) {    }
    setLoading(false)
  }

  const getUserData = async() => {
    try { 
      const value = await axios.get(`${AppConfig.baseUrl}/users/details?id=${params.id}`, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!value.data.error) {
        setUserData(value.data.userDetails)
      }
    } catch (err) {
        console.log(err)
    }
  }

  return (
    <Layout>
      <div className="page-content">
     <Helmet>
          <title>User-details | LeaseJava</title>
       </Helmet>
        <Container fluid>
          <Breadcrumbs title="activitylog" breadcrumbItem={`${userData?.name} - Activity log`} type="activitylog"/> 
          <Row>
            <Col className="col-12">
              <Card>
                {/* <CardBody> */}
                    <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
                    <MUIDataTable
                        title=""
                        data={activityLogData}
                        columns={columns}
                        options={{ 
                        pagination: false, 
                        print:false, 
                        download:true,
                        filter:false, 
                        selectableRows:"none", 
                        search:false, 
                        rowsPerPageOptions:[], 
                        responsive:'scroll',
                        viewColumns:false,
                        downloadOptions:{
                          filename:'activity_log.csv'
                        },
                        textLabels: {
                          body: {
                            noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                          },
                        },
                        onDownload:( buildHead, buildBody, columns, data) =>{
                          try{
                            const formattedData = []
                            for(const [index,row] of data.entries()){
                              const newRow = [ ...row.data ];
                              newRow[0] = getActivity(newRow[0])
                              newRow[1] = newRow[1] ? newRow[1] : ''
                              newRow[2] = moment(newRow[2]).format('LLLL')
                              formattedData.push({ ...row, data: newRow })
                            }
                            const csv = buildHead(columns) + buildBody(formattedData);
                            return csv
                          }catch(err){
                            console.log(err)
                            return buildHead(columns) + buildBody(data);
                          }                              
                        }
                        }}
                    />
                    </ThemeProvider>
                {/* </CardBody> */}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}


export default ActivityLog
