import React, { useEffect, useState } from "react"
import { ButtonDropdown, Modal, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap";
import { Link} from "react-router-dom";

const ButtonDropdownLease = (prop) => {
    const {lease,setScoreOpen,setLease} = prop
    const [actionDropdown, setActionDropdown] = useState(false);

    return (
        <React.Fragment>
            <div className="btn-group mb-2 dropstart">
                <ButtonDropdown
                    isOpen={actionDropdown}
                    toggle={() => setActionDropdown(!actionDropdown)}
                    className="me-2"
                >
                    <DropdownToggle
                        caret
                        className="btn bg-theme-color btn-sm"
                    >
                        Action <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem className="text-success text-black" onClick={()=>{setScoreOpen(true); setLease(lease)}}> View Score</DropdownItem>
                        <DropdownItem tag={Link} to={{pathname:'/lease/details', search: lease && lease._id}}> View Details</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        </React.Fragment>
    )
}

export default ButtonDropdownLease