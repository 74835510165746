import React, { useEffect, useState } from "react"
import {Helmet} from "react-helmet"
import {
    Card,
    CardBody,
    Col,
    Container,
    InputGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import classnames from "classnames"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import { number, object, string } from "yup";
import { useFormik } from "formik";
import moment from "moment";
import Layout from "components/VerticalLayout";

const AddAccount = () => {
    const navigate = useNavigate();
    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [isChecked, setIsChecked] = useState(false)
    const [loading, setLoading] = useState(false);

    const step1Schema = object().shape({
        company: string().required("* Company name is required"),
        contact_person: string().required("* Contact name is required"),
        street_address: string().required("* Company address is required"),
        city: string().required("* City is required"),
        state: string().required("* State is required"),
        email: string().email("Please enter valid email").required("* Email is required"),
        phone_number: string().length(10, "Please enter valid phone number").required("* Phone number is required"),
        primary_contact: string().length(10, "Please enter valid phone number").required("* Primary contact is required"),
    });

    const step2Schema = object().shape({
        admin_name: string().required("* Admin name is required"),
        admin_email: string().email("Please enter valid email").required("* Admin email is required")
    });

    const step3Schema = object().shape({
        start_date: string().required("Start date is required"),
        discount: string().required("Discount is required"),
    });
  
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
			company: "",
			contact_person: "",
			street_address: "",
            street_address_2: "",
            city: "",
            state: "",
            email: "",
            phone_number: "",
            primary_contact: "",
            admin_name: "",
            title: "",
            admin_email: "",
            discount: "DISC0",
            start_date: moment().format('MM/DD/YYYY'),
            is_online: false,
            processing_name : '',
            processing_email : '', 
            processing_phonenumber : '',
            processing_emails :'',
        },
        validationSchema: activeTab === 1 ? step1Schema : activeTab === 2 ? step2Schema : step3Schema,
        onSubmit: async(values) => {
            setLoading(true);
            try {
                if(activeTab < 3){
                    const modifiedSteps = [...passedSteps, activeTab + 1]
                    setactiveTab(activeTab + 1);
                    setPassedSteps(modifiedSteps);
                }
                else{
                    values.start_date = values.start_date ? new Date(moment(values.start_date).format('YYYY-MM-DD')) : new Date(moment().format('YYYY-MM-DD'));
                    const {data} = await axios.post(`${AppConfig.baseUrl}/customer/addCustomer`, values);
                    if(!data.error && data.session && data.session.url){
                        window.location.href = data.session.url
                    }
                    else if(!data.error){
                        toast.success('Customer account created successfully');
                        navigate('/accounts')
                    }
                    else{
                        throw new Error(data.title);
                    }
                }
            } catch (error) {
                toast.error(error.message || 'Something went wrong');
            }
            setLoading(false)
        }
    });

    useEffect(() => {
        if(isChecked){
            formik.setFieldValue("admin_name", formik.values.contact_person)
            formik.setFieldValue("admin_email", formik.values.email)
            formik.setFieldTouched("admin_name", false)
            formik.setFieldTouched("admin_email", false)
        }
        else{
            formik.setFieldValue("admin_name", "")
            formik.setFieldValue("admin_email", "")
        }
    // eslint-disable-next-line
    }, [isChecked])

    const handleChange = async(e, key) => {
        if(key === 'details_check'){
            setIsChecked(!isChecked);
        }
    }
    return (
        <Layout>
            <div className="page-content">
               <Helmet>
                    <title>LeaseJava | Add New Account</title>
               </Helmet>
                <Container fluid={true}>
                    <Breadcrumbs title="Forms" breadcrumbItem="Add New Account" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody style={{ padding: 0 }}>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="wizard clearfix">
                                            <div className="steps clearfix">
                                                <ul>
                                                    <NavItem
                                                        className={classnames({ current: activeTab === 1 })}
                                                    >
                                                        <NavLink
                                                            className={classnames({ current: activeTab === 1 })}
                                                            disabled
                                                        >
                                                            <span className="number">1</span> Company Details
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem
                                                        className={classnames({ current: activeTab === 2 })}
                                                    >
                                                        <NavLink
                                                            className={classnames({ active: activeTab === 2 })}
                                                            disabled
                                                        >
                                                            <span className="number">2</span> Admin
                                                            Details
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem
                                                        className={classnames({ current: activeTab === 3 })}
                                                    >
                                                        <NavLink
                                                            className={classnames({ active: activeTab === 3 })}
                                                            disabled
                                                        >
                                                            <span className="number">3</span> Pricing Details
                                                        </NavLink>
                                                    </NavItem>
                                                </ul>
                                            </div>
                                            <div className="content clearfix mt-4">
                                                <TabContent activeTab={activeTab}>
                                                    <TabPane tabId={1}>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label for="contact_person">
                                                                        Contact name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="contact_person"
                                                                        onChange={(e) => {
                                                                            if(isChecked){
                                                                                formik.setFieldValue("admin_name", e.target.value)
                                                                            }
                                                                            formik.handleChange(e);
                                                                        }}
                                                                        onBlur={() => formik.setFieldTouched("contact_person")}
                                                                        value={formik.values.contact_person}
                                                                    />
                                                                    {formik.touched.contact_person && formik.errors.contact_person && (
                                                                        <div className="text-danger">{formik.errors.contact_person}</div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label for="company">
                                                                        Company name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="company"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={() => formik.setFieldTouched("company")}
                                                                        value={formik.values.company}
                                                                    />
                                                                    {formik.touched.company && formik.errors.company && (
                                                                        <div className="text-danger">{formik.errors.company}</div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label for="street_address">
                                                                        Company street address
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        rows={1}
                                                                        className="form-control"
                                                                        id="street_address"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={() => formik.setFieldTouched("street_address")}
                                                                        value={formik.values.street_address}
                                                                    />
                                                                    {formik.touched.street_address && formik.errors.street_address && (
                                                                        <div className="text-danger">{formik.errors.street_address}</div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label for="street_address_2">
                                                                        Company street address 2 (Optional)
                                                                    </Label>
                                                                    <Input
                                                                        type="textarea"
                                                                        rows={1}
                                                                        className="form-control"
                                                                        id="street_address_2"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={() => formik.setFieldTouched("street_address_2")}
                                                                        value={formik.values.street_address_2}
                                                                    />
                                                                    {formik.touched.street_address_2 && formik.errors.street_address_2 && (
                                                                        <div className="text-danger">{formik.errors.street_address_2}</div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label for="city">
                                                                        City
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="city"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={() => formik.setFieldTouched("city")}
                                                                        value={formik.values.city}
                                                                    />
                                                                    {formik.touched.city && formik.errors.city && (
                                                                        <div className="text-danger">{formik.errors.city}</div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label for="state">
                                                                        State
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="state"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={() => formik.setFieldTouched("state")}
                                                                        value={formik.values.state}
                                                                    />
                                                                    {formik.touched.state && formik.errors.state && (
                                                                        <div className="text-danger">{formik.errors.state}</div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label for="email">
                                                                        Email
                                                                    </Label>
                                                                    <Input
                                                                        type="email"
                                                                        className="form-control"
                                                                        id="email"
                                                                        onChange={(e) => {
                                                                            if(isChecked){
                                                                                formik.setFieldValue("admin_email", e.target.value)
                                                                            }
                                                                            formik.handleChange(e);
                                                                        }}
                                                                        onBlur={() => formik.setFieldTouched("email")}
                                                                        value={formik.values.email}
                                                                    />
                                                                    {formik.touched.email && formik.errors.email && (
                                                                        <div className="text-danger">{formik.errors.email}</div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label for="phone_number">
                                                                        Phone
                                                                    </Label>
                                                                    <Input
                                                                        type="number"
                                                                        className="form-control"
                                                                        id="phone_number"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={() => formik.setFieldTouched("phone_number")}
                                                                        value={formik.values.phone_number}
                                                                    />
                                                                    {formik.touched.phone_number && formik.errors.phone_number && (
                                                                        <div className="text-danger">{formik.errors.phone_number}</div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <div className="mb-3">
                                                                    <Label for="primary_contact">
                                                                        Primary contact
                                                                    </Label>
                                                                    <input
                                                                        type="number"
                                                                        id="primary_contact"
                                                                        className="form-control"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={() => formik.setFieldTouched("primary_contact")}
                                                                        value={formik.values.primary_contact}
                                                                    />
                                                                    {formik.touched.primary_contact && formik.errors.primary_contact && (
                                                                        <div className="text-danger">{formik.errors.primary_contact}</div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                    <TabPane tabId={2}>
                                                        <div className="mb-4">
                                                            <Row>
                                                                <Col md="12" lg="12" xl="12" xxl="12">
                                                                    <div className="form-check mb-3">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            onChange={(e) => {
                                                                                handleChange(e, 'details_check')
                                                                            }} 
                                                                            checked={isChecked}
                                                                            id="details_check"
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="details_check"
                                                                        >
                                                                            Admin details are same as company contact details
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="6" lg="6" xl="6" xxl="6">
                                                                    <div className="mb-3">
                                                                        <Label for="admin_name">
                                                                            Admin Name
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="admin_name"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={() => formik.setFieldTouched("admin_name")}
                                                                            value={formik.values.admin_name}
                                                                        />
                                                                        {formik.touched.admin_name && formik.errors.admin_name && (
                                                                            <div className="text-danger">{formik.errors.admin_name}</div>
                                                                        )}
                                                                    </div>
                                                                </Col>

                                                                <Col md="6" lg="6" xl="6" xxl="6">
                                                                    <div className="mb-3">
                                                                        <Label for="title">
                                                                            Title
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="title"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={() => formik.setFieldTouched("title")}
                                                                            value={formik.values.title}
                                                                        />
                                                                        {formik.touched.title && formik.errors.title && (
                                                                            <div className="text-danger">{formik.errors.title}</div>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="6" lg="6" xl="6" xxl="6">
                                                                    <div className="mb-3">
                                                                        <Label for="admin_email">
                                                                            Email
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="admin_email"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={() => formik.setFieldTouched("admin_email")}
                                                                            value={formik.values.admin_email}
                                                                        />
                                                                        {formik.touched.admin_email && formik.errors.admin_email && (
                                                                            <div className="text-danger">{formik.errors.admin_email}</div>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <div className="mt-4">
                                                            <Row>
                                                            <Col md="6" lg="6" xl="6" xxl="6">
                                                                <div className="mb-3">
                                                                    <Label for="processing_name">
                                                                        Payment processing admin name
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="processing_name"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={() => formik.setFieldTouched("processing_name")}
                                                                        value={formik.values.processing_name}
                                                                    />
                                                                    {formik.touched.processing_name && formik.errors.processing_name && (
                                                                        <div className="text-danger">{formik.errors.processing_name}</div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            <Col md="6" lg="6" xl="6" xxl="6">
                                                                <div className="mb-3">
                                                                    <Label for="processing_email">
                                                                        Payment processing admin email
                                                                    </Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="processing_email"
                                                                        onChange={formik.handleChange}
                                                                        onBlur={() => formik.setFieldTouched("processing_email")}
                                                                        value={formik.values.processing_email}
                                                                    />
                                                                    {formik.touched.processing_email && formik.errors.processing_email && (
                                                                        <div className="text-danger">{formik.errors.processing_email}</div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="6" lg="6" xl="6" xxl="6">
                                                                    <div className="mb-3">
                                                                        <Label for="processing_phonenumber">
                                                                            Payment processing admin number
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="processing_phonenumber"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={() => formik.setFieldTouched("processing_phonenumber")}
                                                                            value={formik.values.processing_phonenumber}
                                                                        />
                                                                        {formik.touched.processing_phonenumber && formik.errors.processing_phonenumber && (
                                                                            <div className="text-danger">{formik.errors.processing_phonenumber}</div>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                                <Col md="6" lg="6" xl="6" xxl="6">
                                                                    <div className="mb-3">
                                                                        <Label for="processing_emails">
                                                                            Others receiving payment,invoicing and account status emails
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="processing_emails"
                                                                            onChange={formik.handleChange}
                                                                            onBlur={() => formik.setFieldTouched("processing_emails")}
                                                                            value={formik.values.processing_emails}
                                                                        />
                                                                        {formik.touched.processing_emails && formik.errors.processing_emails && (
                                                                            <div className="text-danger">{formik.errors.processing_emails}</div>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </TabPane>
                                                    <TabPane tabId={3}>
                                                        <div>
                                                            <Row>
                                                                <Col md="6" lg="6" xl="6" xxl="6">
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="mt-4">
                                                                                <Label for="start_date">
                                                                                    Start date
                                                                                </Label>
                                                                                <InputGroup>
                                                                                    <Flatpickr
                                                                                        onClose={([date]) => {
                                                                                            formik.setFieldValue("start_date", date ? moment(date).format('MM/DD/YYYY') : "")
                                                                                        }}
                                                                                        name="start_date"
                                                                                        onBlur={() => formik.setFieldTouched("start_date")}
                                                                                        value={formik.values.start_date}
                                                                                        className="form-control d-block bg-white"
                                                                                        options={{
                                                                                            dateFormat: "m/d/Y",
                                                                                            allowInput: true,
                                                                                            minDate: moment().format('MM/DD/YYYY'),
                                                                                            maxDate: moment().add(1, 'month').format('MM/DD/YYYY')
                                                                                        }}
                                                                                    />
                                                                                    <div className="input-group-append">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                            disabled
                                                                                        >
                                                                                            <i
                                                                                                className="fa fa-calendar"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        </button>
                                                                                    </div>
                                                                                    {formik.touched.start_date && formik.errors.start_date && (
                                                                                        <div className="text-danger">{formik.errors.start_date}</div>
                                                                                    )}
                                                                                </InputGroup>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="mt-3">
                                                                                <h5 className="font-size-14">Discount Code: <span className="text-danger">*</span> </h5>
                                                                                <Row>
                                                                                    <Col md="6" lg="6" xl="6" xxl="6">
                                                                                        <div className="form-check">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                name="discount"
                                                                                                id="DISC0"
                                                                                                value="DISC0"
                                                                                                defaultChecked
                                                                                                onChange={formik.handleChange}
                                                                                                onBlur={() => formik.setFieldTouched("discount")}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label radioLabel"
                                                                                                htmlFor="DISC0"
                                                                                            >
                                                                                                No Discount
                                                                                            </label>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col md="6" lg="6" xl="6" xxl="6">
                                                                                        <div className="form-check">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                name="discount"
                                                                                                id="WEBD10"
                                                                                                value="WEBD10"
                                                                                                onChange={formik.handleChange}
                                                                                                onBlur={() => formik.setFieldTouched("discount")}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label radioLabel"
                                                                                                htmlFor="WEBD10"
                                                                                            >
                                                                                                WEBD10
                                                                                            </label>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col md="6" lg="6" xl="6" xxl="6">
                                                                                        <div className="form-check">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                name="discount"
                                                                                                id="FFAD15"
                                                                                                value="FFAD15"
                                                                                                onChange={formik.handleChange}
                                                                                                onBlur={() => formik.setFieldTouched("discount")}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label radioLabel"
                                                                                                htmlFor="FFAD15"
                                                                                            >
                                                                                                FFAD15
                                                                                            </label>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col md="6" lg="6" xl="6" xxl="6">
                                                                                        <div className="form-check">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                name="discount"
                                                                                                id="RCD20"
                                                                                                value="RCD20"
                                                                                                onChange={formik.handleChange}
                                                                                                onBlur={() => formik.setFieldTouched("discount")}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label radioLabel"
                                                                                                htmlFor="RCD20"
                                                                                            >
                                                                                                RCD20
                                                                                            </label>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col md="6" lg="6" xl="6" xxl="6">
                                                                                        <div className="form-check">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                name="discount"
                                                                                                id="FFD30"
                                                                                                value="FFD30"
                                                                                                onChange={formik.handleChange}
                                                                                                onBlur={() => formik.setFieldTouched("discount")}
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label radioLabel"
                                                                                                htmlFor="FFD30"
                                                                                            >
                                                                                                FFD30
                                                                                            </label>
                                                                                        </div>
                                                                                    </Col>
                                                                                    {formik.touched.discount && formik.errors.discount && (
                                                                                        <div className="text-danger">{formik.errors.discount}</div>
                                                                                    )}
                                                                                </Row>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className="mt-4">
                                                                                <Label for="is_online">
                                                                                    Online payment
                                                                                </Label>
                                                                                <InputGroup>
                                                                                    <div className="form-check form-switch form-switch-lg" style={{ alignContent: 'center'}}>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id="is_online"
                                                                                            checked={formik.values.is_online}
                                                                                            disabled={loading}
                                                                                            onChange={formik.handleChange}
                                                                                        />
                                                                                        {
                                                                                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                                                        }
                                                                                    </div>
                                                                                </InputGroup>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                            <div className="actions clearfix">
                                                <ul>
                                                    {
                                                        activeTab === 3 ?
                                                            <React.Fragment>
                                                                <li
                                                                    className={
                                                                        activeTab === 1 ? "previous disabled" : "previous"
                                                                    }
                                                                >
                                                                    <Link
                                                                        className="btn bg-theme-color w-md"
                                                                        to="#"
                                                                        onClick={() => {
                                                                            setactiveTab(activeTab - 1)
                                                                        }}
                                                                    >
                                                                        PREVIOUS
                                                                    </Link>
                                                                </li>
                                                                <li
                                                                    className={
                                                                        activeTab === 1 ? "previous disabled" : "previous"
                                                                    }
                                                                >
                                                                    <button type="submit" className="btn bg-theme-color w-md me-2" disabled={loading}>
                                                                        {
                                                                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                                        }
                                                                        SUBMIT
                                                                    </button>
                                                                </li>
                                                            </React.Fragment>
                                                        :
                                                            <React.Fragment>
                                                                <li
                                                                    className={
                                                                        activeTab === 1 ? "previous disabled" : "previous"
                                                                    }
                                                                >
                                                                    <Link
                                                                        className="btn bg-theme-color w-md"
                                                                        to="#"
                                                                        onClick={() => {
                                                                            setactiveTab(activeTab - 1)
                                                                        }}
                                                                    >
                                                                        PREVIOUS
                                                                    </Link>
                                                                </li>
                                                                <li
                                                                    className={activeTab === 3 ? "next disabled" : "next"}
                                                                >
                                                                    <Link
                                                                        className="btn bg-theme-color w-md me-2"
                                                                        to="#"
                                                                        onClick={async() => {
                                                                            formik.handleSubmit()
                                                                        }}
                                                                    >
                                                                        NEXT
                                                                    </Link>
                                                                </li>
                                                            </React.Fragment>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

export default AddAccount
