import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Progress,Modal, Spinner} from "reactstrap"
import { useLocation } from "react-router-dom"
import {Helmet} from "react-helmet";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import moment from 'moment';
import { capitalize } from "lodash"
import axios from "axios";
import AppConfig from "constants/config";
import ButtonDropdown from "./ButtonDropdown"
import toast from 'react-hot-toast';
import Layout from "components/VerticalLayout";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const Leases = () => {
  const location = useLocation();
  const [id, setId] = useState('');
  const [leaseData, setLeaseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState([])
  const [scoreOpen, setScoreOpen] = useState(false);
  const [lease,setLease] = useState({})
  const [cases,setCases] = useState([])
  const columns = [
    {
      label: "Lease name",
      name: "name",
      options:{
        sort : true,
        search: true,
        customBodyRenderLite: (dataIndex) => (
          <>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="avatar-sm">
                  <button
                    type="button"
                    className="btn rounded-circle avatar-sm bg-theme-color mt-0 ms-0 text-align-center"
                  >
                    {leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Headquarters' ? <i className='bx bx-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Super Car' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Trade Center' ? <i className='bx bxs-building-house text-white' style={{ fontSize: '150%', padding: '0' }}></i> 
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Vehicle' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : ((leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Building') || (leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Office')) ? <i className='bx bxs-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Land' ? <i className='bx bxs-landscape text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Equipment' ? <i className='bx bx-list-ol text-white' style={{ fontSize: '180%', padding: '0' }}></i>
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Others' ? <i className='bx bxs-customize text-white' style={{ fontSize: '150%', padding: '0' }}></i> : ''
                    }
                  </button>
                </div>
                <div className="client">
                  <div>{(leaseData && leaseData[dataIndex] && leaseData[dataIndex].name).toUpperCase()}</div>
                </div>
              </div>
          </>
        ),
      }
    },
    {
      name: "audit_score",
      label: "Audit score",
      options:{
        sort : true,
        search: true,
        customBodyRenderLite: (dataIndex) => (
          <div className={`table-data-margin ${ leaseData && leaseData[dataIndex] && leaseData[dataIndex].status == 'Terminated' && leaseData && leaseData[dataIndex] && leaseData[dataIndex].audit_score == '7/8' ? 'text-success' : leaseData && leaseData[dataIndex] && leaseData[dataIndex].audit_score == '8/8' ? 'text-success' : leaseData && leaseData[dataIndex] && leaseData[dataIndex].status == 'Terminated' && leaseData && leaseData[dataIndex] && leaseData[dataIndex].audit_score == '10/11' ? 'text-success' : leaseData && leaseData[dataIndex] && leaseData[dataIndex].audit_score == '11/11' ? 'text-success' : 'text-danger'}`}>
            <div>{leaseData && leaseData[dataIndex] && leaseData[dataIndex].audit_score}</div>
          </div>
        ),
      }
    },
    {
      name: "enddate",
      label: "Lease term",
      options:{
        sort : true,
        search: false,
        customBodyRenderLite: (dataIndex) => {
          let val = progressBarForLeases(leaseData && leaseData[dataIndex]);
          return <div className="mt-2">
            <div className="mb-2">
              <Progress
                value={val}
                color={'warning'}
                className="progress-sm"
              ></Progress>
            </div>
            <div className="">
              <span className="text-muted mb-0">{moment.utc(leaseData && leaseData[dataIndex] && leaseData[dataIndex].commencement).format('MM/DD/YYYY')}</span> -  
              <span className="text-muted mb-0">{moment.utc(leaseData && leaseData[dataIndex] && leaseData[dataIndex].terminal_end_date).format('MM/DD/YYYY')}</span>
            </div>
          </div>
        }
      }
    },
    {
      name: "classification",
      label: "Classification",
      options:{
        sort : true,
        search: true,
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease == 'operating' ? 'Operating' : 'Financing'}</div>
          </div>
        ),
      }
    },
    {
      name: "reporting_standard",
      label: "Reporting Standard",
      options:{
        sort : true,
        search: true,
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease ?  lease : "FASB ASC 842"}</div>
          </div>
        ),
      }
    },
    {
      name: "calculation_type",
      label: "Lease Type",
      options:{
        sort : true,
        search: true,
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease ? capitalize(lease) : 'Daily'}</div>
          </div>
        ),
      }
    },
    {
      name: "status",
      label: "Status",
      options:{
        sort : true,
        search: true,
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease}</div>
          </div>
        ),
      }
    },
    {
      name: "menu",
      label: 'Actions',
      options:{
        customBodyRenderLite: (dataIndex) => (
          <div className="mt-2">
            <ButtonDropdown loading={loading} lease={leaseData && leaseData[dataIndex]} setScoreOpen={setScoreOpen} setLease={setLease}/>
          </div>
        )
      }
    },
  ];

  const progressBarForLeases = (data) => {
    let today = moment(new Date(), 'M/D/YYYY')
    let start = moment(new Date(data.commencement), 'M/D/YYYY')
    let end = moment(new Date(data.terminal_end_date), 'M/D/YYYY')
    let startEndDiff = end.diff(start, 'days');
    let todayEndDiff = end.diff(today, 'days');
    let per = ((startEndDiff - todayEndDiff)/startEndDiff) * 100;
    return Math.round(per)
  }

  const toggleScore = () => {
      setScoreOpen(!scoreOpen);
      setLease({})      
  }

  useEffect(()=>{
    setCases(Object.keys(lease.audit_result || {}))
  },[lease])

  useEffect(() => {
      setId((location.search).replace('?', ''));
  }, [])

  const getClient = async () => {
    setLoading(true)
    try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/super_admin/details?id=${id}`,{
            headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
            setClient(data.clientDetails)
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  const getLease = async () => {
    setLoading(true)
    try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/super_admin/getClientLeases?id=${id}`,{
            headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
          setLeaseData(data.leases)
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
        toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  useEffect(() => {
    if(id){
        getClient();
        getLease();
    }
  },[id])

  return (
    <Layout>
      <div className="page-content">
       <Helmet>
          <title>LeaseJava | Leases</title>
       </Helmet>
        <div className="container-fluid">
          <Breadcrumbs title="lease " breadcrumbItem={`${client.client} - leases`}/>
          <Row>
            <Col className="col-12">
                <Card>
                    <CardBody>
                    <ThemeProvider theme={createTheme({components: {...getMuiTheme()}})}>
                    <MUIDataTable
                        title=""
                        data={leaseData}
                        columns={columns}
                        options={{ 
                        rowsPerPage:10, 
                        print:false, 
                        download:false, 
                        filter:false, 
                        pagination : false,
                        viewColumns: false,
                        selectableRows:"none", 
                        search:false, 
                        rowsPerPageOptions:[], 
                        responsive:'scroll',
                        textLabels: {
                          body: {
                            noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                          },
                            viewColumns: {
                            title: "",
                            },
                        } 
                        }}
                    />
                    </ThemeProvider>
                    </CardBody>
                </Card>              
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={scoreOpen}
        toggle={() => {
            toggleScore();
        }}
        centered
      >
        <div className="modal-header">
            <h5 className="modal-title mt-0">Audit score</h5>
            <button
                type="button"
                onClick={() => {
                  toggleScore();
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
          <ul className="verti-timeline list-unstyled">
            {
              Array.from(Array(Math.ceil(cases.length/2)).keys()).map((v,i)=>
              { 
                return(
                  <Row key={i}>
                    {
                    cases[i*2+0] ?
                      <Col>
                        <li className="event-list">
                          <div className="event-timeline-dot me-3">                            
                            { lease.status == 'Terminated' && i*2+0 == 0 ? <i className='bx bx-minus-circle text-secondary font-size-22'></i> : lease.audit_result && lease.audit_result[cases[i*2+0]] ? <i className='bx bx-check-circle text-success font-size-22'></i> : <i className='bx bx-x-circle text-danger font-size-22'></i>}
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h5>{cases[i*2+0]}</h5>
                              </div>
                            </div>
                          </div>
                        </li>
                      </Col>
                    : ''
                    }
                    {
                    cases[i*2+1] ?
                      <Col>
                        <li className="event-list">
                          <div className="event-timeline-dot me-3">
                            {lease.audit_result && lease.audit_result[cases[i*2+1]] ? <i className='bx bx-check-circle text-success font-size-22'></i>:<i className='bx bx-x-circle text-danger font-size-22'></i>}
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h5>{cases[i*2+1]}</h5>
                              </div>
                            </div>
                          </div>
                        </li>
                      </Col>
                    : ''
                    }
                  </Row>
              )
              })
            }
            
          </ul>
        </div>
      </Modal>
    </Layout>
    )
}
export default Leases