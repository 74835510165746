import React, { useState } from "react"
import { ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"

const ButtonDropdownClient = (prop) => {
  const {setDeleteOpen, setSelectedUser, user, type, setSoftDelete, setRestoreUser, setDeleteUser} = prop;
  const [viewBtn, setViewBtn] = useState(false);
    return (
        <div className="btn-group mb-2 dropstart">
          <ButtonDropdown
            isOpen={viewBtn}
            toggle={() => { setViewBtn(!viewBtn) }}
          >
            <DropdownToggle
              caret
              color="lbsuperadmin"
              className="btn btn-primary btn-sm"
            >
              Actions &nbsp;{" "}
              <i className="mdi mdi-chevron-down" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={Link} to={{pathname:'/accounts/details', search: user && user._id}}> View</DropdownItem>
              {
                type === 'account'  && user && user.customer_id && user.customer_id.is_deleted === false
                ? <DropdownItem  tag={Link} to={{pathname:'/accounts/edit', search: user && user._id}}> Edit Account</DropdownItem> 
                : type !== 'account' ? <DropdownItem  tag={Link} to={{pathname:'/accounts/edit', search: user && user._id}}> Edit </DropdownItem> : ''
              }
              {
                type !== 'account' ?
                  <DropdownItem onClick={() => {setDeleteOpen(true); setSelectedUser(user)}}> Remove Access</DropdownItem>
                :''
              }
              {
                type === 'account'  && user && user.customer_id && user.customer_id.is_deleted === false
                ? <DropdownItem tag={Link} to={{pathname:'/accounts/payments', search: user && user._id}}> Payments</DropdownItem> : ''
              }
              {
                type === 'account' && user && user.customer_id && user.customer_id.is_deleted === false 
                ? <DropdownItem  tag={Link} to={{pathname:'/accounts/modules', search: user && user._id}}> Modules</DropdownItem> : ''
              }
              {
                type === 'account' && user && user.customer_id && user.customer_id.is_deleted === false 
                ? <DropdownItem onClick={() => {setSoftDelete(true); setSelectedUser(user)}}> Delete </DropdownItem> :''
              }
              {
                type === 'account' && user && user.customer_id && user.customer_id.is_deleted === true 
                ? <DropdownItem onClick={() => {setRestoreUser(true); setSelectedUser(user)}}> Restore </DropdownItem> : ''
              }{
                type === 'account' && user && user.customer_id && user.customer_id.is_deleted === true 
                ? <DropdownItem onClick={() => {setDeleteUser(true); setSelectedUser(user)}}> Permanently delete </DropdownItem> : ''
              }
            </DropdownMenu>
          </ButtonDropdown>
        </div>
    )
}

ButtonDropdownClient.propTypes = {
  state: PropTypes.any,
  handleClick: PropTypes.any
}

export default ButtonDropdownClient