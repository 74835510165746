import React from "react"
import PropTypes from 'prop-types'
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  const navigate = useNavigate();
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
          {
            props.breadcrumbItem == 'Accounts' &&
            <div className="mb-0">
              <button
                type="button"
                className="btn bg-theme-color"
                style={{margin: 'auto 0'}}
                onClick={() => {
                  navigate('/accounts/add')
                }}
              >
                ADD NEW
              </button>
            </div>
          }
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
