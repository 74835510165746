import React, { useEffect, useState, useContext } from "react"
import {Helmet} from "react-helmet";
import { useLocation } from "react-router-dom"
import {
    Card, CardBody, Col, Container, Row, UncontrolledAlert, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, TabPane, NavLink, NavItem, TabContent, Nav, Label, Form, Modal, Spinner} from "reactstrap"
import NewJournalEntries from "./journalEntries";
import Payment from "./payment";
import Liability from "./liability";
import STBalance from "./stbalance"
import RouAsset from "./rouAsset";
import Summary from "./summary";
import LeaseSummary from "./leaseSummary";
import GlMappingJE from "./GlMappingJE"; 

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import AppConfig from 'constants/config';
import toast from 'react-hot-toast';
import moment from 'moment';
import DownloadDropdown from "./downloadDropdown";
import LeaseLogs from "./leaseLogs";
import Layout from "components/VerticalLayout";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const LeaseDetails = () => {
    const location = useLocation();
    const [id, setId] = useState('');
    const [leaseDetails, setLeaseDetails] = useState('');
    const [ jeDates, setJeDates ] = useState({});
    const [paymentsData, setPaymentsData] = useState([]);
    const [refundAmount, setRefundAmount] = useState('');
    const [leaseDraft, setLeaseDraft] = useState('');
    const [loading, setLoading] = useState(false)
    const [currency, setCurrency] = useState("$");
    const [detailsMode, setDetailsMode] = useState("");
    const [clientData, setClientData] = useState({});
    const [shortTerm, setShortTerm] = useState(false);
    const [rouArrData, setRouArrData] = useState({});
    const [modData, setModData] = useState("");
    const [impairments, setImpairments] = useState([])
    const [useful, setUseful] = useState([])
    const [value, setValue] = useState('1');
    const [mode,setMode] = useState('lessee')

    useEffect(() => {
        setId((location.search).replace('?', ''));
    }, [])

    const hitApi = async () => {
        setLoading(true)
        let {data:value} = await axios.get(`${AppConfig.baseUrl}/super_admin/lease_details?Id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        let { data } = await axios.get(`${AppConfig.baseUrl}/lease_draft/get_draft?id=${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if (data && !data.error) {
            setLeaseDraft(data.lease)
        }
        if (value.error === false) {
            setLeaseDetails(value.data)
            setShortTerm(value.data.is_short_term)
            setJeDates(value.journal_dates)
            setImpairments(value.impairArr)            
            setUseful(value.usefulArr)
            let client_id = value.data && value.data.client_id ? value.data.client_id._id : ''
            const { data } = await axios.get(`${AppConfig.baseUrl}/super_admin/details?id=${client_id}&mode=${mode ? mode : 'lessee'}`, {
                headers: { token: localStorage.getItem("token") }
              });
              if (!data.error) {
                setClientData(data.clientDetails)
              }
            setCurrency(value.data && value.data.currency ? value.data.currency.symbol : '$')
            setDetailsMode(value.data && value.data.mode ? value.data.mode : 'lessee')
            setValue('1')
            setMode(value.data ? value.data.mode : 'lessee')
            let payment = value.data && value.data?.paymentData && value.data?.paymentData?.payments || [];
            let filter = []
            filter = payment.filter(e => moment(moment(e.due_date).format('YYYY-MM-DD'), 'YYYY-MM-DD').isSameOrAfter(moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')))
            let pvcData = value.data && value.data?.pvcData && value.data?.pvcData;
            setPaymentsData(pvcData)
            let total = 0
            if (value.data && value.data.paymentData && value.data.paymentData.payments && value.data.paymentData.payments.length > 0) {
                total = value.data.paymentData.payments.reduce((total, val) => {
                    if (val && val.type && (val.type.includes("Rent Deposit") || val.type.includes("Refundable Deposit"))) {
                        return total + Number(val.refund_amount)
                    } else {
                        return total
                    }
                }, 0)
            }
            setRefundAmount(total)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (mode && detailsMode && mode != detailsMode) {
            window.location.href = `/${localStorage.getItem('company')}/leases`
        }
    }, [mode, detailsMode])

    useEffect(() => {
        let modData = leaseDetails && leaseDetails.modifiedLease && leaseDetails.modifiedLease.length > 0 ? leaseDetails.modifiedLease.find(v=>v.is_short_term == true) : ''
        setModData(modData)       
    },[leaseDetails])

    useEffect(() => {
        if(id){
            hitApi()
        }
    },[id])

    useEffect(() => {
        if(id){
            getRouArrData()
        }
    },[mode, id])

    const getRouArrData = async()=>{
        let {data:value} = await axios.get(`${AppConfig.baseUrl}/rou_asset/get_rou_asset?Id=${id}&mode=${mode || 'lessee'}`, {
            headers: {
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            }
        })
        if(!value.error){
            setRouArrData(value.data)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseJava | Lease Details</title>
                </Helmet>
                <Container fluid>
                    {
                        loading ?
                            <div className="text-center" style={{ marginTop: '35vh' }}><Spinner color="primary" className="text-lbusers" /></div>
                            :
                            leaseDetails && !leaseDetails.is_processing ?
                                <>
                                    <div className="d-flex justify-content-between">
                                    <Breadcrumbs title="leaseDeatails"
                                        breadcrumbItem={`${leaseDetails && leaseDetails.name} - ${leaseDetails&&leaseDetails?.classification == "financing" ? (leaseDetails && leaseDetails.reporting_standard ? leaseDetails.reporting_standard : "FASB ASC 842") +" FINANCING LEASE" : leaseDetails&&leaseDetails?.classification == "operating" ? (leaseDetails && leaseDetails.reporting_standard ? leaseDetails.reporting_standard : "FASB ASC 842")+" OPERATING LEASE" :
                                        leaseDetails&&leaseDetails?.classification == "determine" ? 'LeaseJava to determine' : ''}`}
                                    />
                                        <div style={{ float: 'right' }}>
                                            <LeaseLogs leaseDetails={leaseDetails} mode={mode || 'lessee'} />
                                            <DownloadDropdown lease_id={leaseDetails._id} mode={mode} from="details" />
                                        </div>
                                    </div>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <Box>
                                                        <ThemeProvider theme={createTheme({components: {
                                                        MuiTab:{
                                                            styleOverrides:{
                                                                root: {
                                                                    fontFamily:'"Poppins",sans-serif',
                                                                    fontSize: '0.8125rem',
                                                                    fontWeight: 500,
                                                                    color: '#495057',
                                                                    '&:hover':{
                                                                    color: '#005691'
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        }})}>
                                                        <TabContext value={value}>
                                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                            <TabList onChange={handleChange} variant="fullWidth">
                                                                <Tab label="SUMMARY" value="1" />
                                                                <Tab label="PAYMENTS" value="2" />
                                                                {!shortTerm &&
                                                                    <Tab label={detailsMode == 'lessor' ? "RECEIVABLE" : "LIABILITY"} value="3" />                                                                    
                                                                }
                                                                {!shortTerm &&
                                                                    <Tab label={detailsMode == 'lessor' ? "DEFERRED INFLOW OF RESOURCES" : "ROU ASSET"} value="4" />
                                                                }
                                                                {(shortTerm || (modData && modData.is_short_term)) &&
                                                                    <Tab label="ST BALANCE" value={!shortTerm ? "5" : "3"} />
                                                                }
                                                                <Tab label="JOURNAL ENTRIES" value={(!shortTerm && (modData && modData.is_short_term)) ? "6" : shortTerm ? "4" :"5"} />
                                                                <Tab label={ <>
                                                                    <div className="d-flex justify-content-center">
                                                                        <span className="d-none d-sm-block">G/L MAPPED JE</span> 
                                                                        <div style={{marginTop:'-10px'}}>{leaseDetails && !leaseDetails.is_glmap_processed ? <i className="fa fa-circle text-danger" style={{fontSize:'6px'}}></i> : ''}</div>                                                              
                                                                    </div>     
                                                                    <span className="d-none d-sm-block" style={{fontSize:'11px'}}>(Experimental)</span> 
                                                                    </>
                                                                } value={(!shortTerm && (modData && modData.is_short_term)) ? "7" :shortTerm ? "5" :"6"} />
                                                                {(leaseDetails.is_modified && leaseDetails.is_modified === true) && 
                                                                    <Tab label="MODIFICATIONS" value={(!shortTerm && (modData && modData.is_short_term)) ? "8" :shortTerm ? "6" :"7"} />
                                                                }
                                                            </TabList>
                                                            </Box>
                                                            <TabPanel value="1"><LeaseSummary rouArrData={rouArrData} leaseDetails={leaseDetails} clientData={clientData} currency={currency} paymentsData={paymentsData} leaseDraft={leaseDraft} refundAmount={refundAmount} impairments={impairments} useful={useful}/></TabPanel>
                                                            <TabPanel value="2"><Payment mode={detailsMode} loading={loading} data={leaseDetails && leaseDetails?.paymentData && leaseDetails?.paymentData?.payments} variableData={leaseDraft && leaseDraft.oldVariablePreview ? leaseDraft.oldVariablePreview : []} currency={currency} /></TabPanel>
                                                            {!shortTerm &&
                                                            <>
                                                                <TabPanel value="3"><Liability data={leaseDetails && leaseDetails?.leaseLiability} leaseDetails={leaseDetails} type={leaseDetails?.classification} isModified={leaseDetails.is_modified} currency={currency} /></TabPanel>
                                                                <TabPanel value="4"><RouAsset data={leaseDetails && leaseDetails?.rouAsset} leaseDetails={leaseDetails} currency={currency} mode={detailsMode} isModified={leaseDetails.is_modified} /></TabPanel>
                                                            </>
                                                            }
                                                            {(shortTerm || (modData && modData.is_short_term)) && 
                                                                <TabPanel value={(!shortTerm) ? "5" : "3"}><STBalance data={leaseDetails} type={leaseDetails?.classification} isModified={leaseDetails.is_modified} currency={currency} /></TabPanel>
                                                            } 
                                                            <TabPanel value={(!shortTerm && (modData && modData.is_short_term)) ? "6" : shortTerm ? "4" :"5"}><NewJournalEntries loading={loading} shortTerm={shortTerm} leaseDetails={leaseDetails} data={leaseDetails && leaseDetails?.journalData && leaseDetails?.journalData} detailData={leaseDetails && leaseDetails?.jeDetailData && leaseDetails?.jeDetailData} type={leaseDetails?.classification} isModified={leaseDetails.is_modified} currency={currency} mode={detailsMode} />
                                                            </TabPanel>
                                                            <TabPanel value={(!shortTerm && (modData && modData.is_short_term)) ? "7" : shortTerm ? "5" :"6"}><GlMappingJE loading={loading} data={leaseDetails} jeDates={jeDates} currency={currency} mode={detailsMode} hitApi={hitApi} />
                                                            </TabPanel>
                                                            <TabPanel value={(!shortTerm && (modData && modData.is_short_term)) ? "8" : shortTerm ? "6" :"7"}><Summary data={leaseDetails && leaseDetails?.modifiedLease && leaseDetails?.modifiedLease} leaseDetails={leaseDetails} />
                                                            </TabPanel>
                                                        </TabContext>
                                                        </ThemeProvider>
                                                    </Box>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <div style={{ textAlign: 'center', marginTop: '30vh' }}>
                                    <h6 style={{ fontSize: '20px' }}>
                                        Your lease is currently being processed in the background and you will receive notification when it is completed.
                                        Please do not refresh this screen, but you may use the rest of LeaseJava functionality in the meantime.
                                    </h6>
                                    <div className="text-center" style={{ marginTop: '20px' }}><Spinner color="primary" className="text-lbusers" /></div>
                                </div>
                    }
                </Container>
            </div>
        </Layout>
    )
}


export default LeaseDetails
