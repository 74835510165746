import React from "react";
import logoWhite from "assets/images/logos/logo-long.png"

class Failure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: ''
        };
    }

    componentDidMount(){
        const search = window.location.search.replace('?', '');
        this.setState({type: search})
    }

    render() {
        return (
            <React.Fragment>
 
                <div className="main">
                    <section className="page-header-section ptb-100 bg-image full-height" image-overlay="8" >
                        <div className="background-image-wraper" style={{backgroundImage: "url(background.jpg)",opacity:0.4}}></div>
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12 col-md-9 col-lg-7">
                                    <div className="hero-content-left text-black text-center">
                                    <a href="https://demo.leasejava.com" ><img src={logoWhite} alt="logo" style={{width:"300px"}}/></a>
                                    <br/><br/><br/>
                                        <h2 className="text-black">Oops, something went wrong!</h2>
                                        <p className="lead">
                                            {
                                            this.state.type === 'payment' ?
                                                <>We could not identify that payment method. Please try again.</>
                                                :<>We could not identify that payment method. Please try again.</>
                                            }
                                        </p>
                                        
                                    </div>
                              </div>
                            </div>
                            
                      </div>
                    </section>
                </div>
 
            </React.Fragment>
        );
    }
}

export default Failure;
