import React, { useState, useEffect } from "react"
import { Button, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import axios from "axios";
import AppConfig from "constants/config";
import moment from "moment";

const LeaseLogs = (prop) => {
    const {leaseDetails} = prop
    const [canvasOpen, setCanvasOpen] = useState(false)
    const [activityLeaseData, setActivityLeaseData] = useState([])
    const [loading, setLoading] = useState(false)

    const getLeaseActivity = async () => {
        setLoading(true)
        try {
          const { data } = await axios.get(`${AppConfig.baseUrl}/activity/list_activity?lease_id=${leaseDetails._id}`, {
            headers: { token: localStorage.getItem("token") }
          });
          if (!data.error) {
            setActivityLeaseData(data.data)
          } else {
            throw new Error(data.title);
          }
        } catch (error) {    }
        setLoading(false)
    }

    useEffect(()=>{
        if(leaseDetails && leaseDetails._id){
            getLeaseActivity('');
        }
    },[leaseDetails])

    const getActivity = (d)=>{
        if(d == "add"){ return <>Lease <b>added</b></>}
        else if(d == 'edit'){ return <>Lease <b>edited</b></>}
        else if (d == 'clone') {return <>Lease <b>cloned</b></>}
        else if (d == 'draft') {return <>Lease <b>saved as draft</b></>}
        else if (d == 'terminated') {return <>Lease <b>terminated</b></>} 
        else if (d == 'modify') { return <>Lease <b>modified</b></>} 
        else if (d == 'impairment') { return <><b>Impairment</b> added</>}
        else if (d == 'submit') { return <>Lease <b>submitted</b></>} 
        else if (d == 'review') { return <>Lease <b>reviewed</b></>}
        else if (d == 'delete') { return <>Lease <b>deleted</b></>}
        else if (d == 'active') { return <>Lease turned <b>active</b></>}
        else if (d == 'inactive') { return <>Lease turned <b>inactive</b></>}
    }

    const toggleCanvas = ()=>{
        setCanvasOpen(!canvasOpen)
    }

    return (
        <>
            {
                <Button className="btn btn-md bg-theme-color me-2 mb-4" onClick={()=>setCanvasOpen(true)}>
                    Log
                </Button>
            }
            <Offcanvas
                isOpen={canvasOpen}
                direction="end"
                toggle={toggleCanvas}
            >
                <OffcanvasHeader toggle={toggleCanvas}>LEASE LOG</OffcanvasHeader>
                <OffcanvasBody>
                    <ul className="verti-timeline list-unstyled">
                    {
                        activityLeaseData.map((d, i)=> {
                        return <li key={d._id} className={`event-list ${ i == 0 ? 'active' : ''}`}>
                            <div className="event-timeline-dot">
                                <i className={`bx bxs-right-arrow-circle font-size-18 ${ i== 0 ? 'bx-fade-right' : ''}`}></i>
                            </div>
                            <div className="d-flex">
                                <div className="me-3 d-flex">
                                    <div>
                                        <h5 className="font-size-14">
                                        {moment(d.createdAt).format('MM/DD/YYYY')}
                                        </h5>
                                        <h6>{moment(d.createdAt).format('HH:mm')}</h6>
                                    </div>
                                    <div>
                                        <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <div>
                                    {getActivity(d.activity)}{d.activity != 'active' && d.activity != 'inactive' ?  ` by ${d.user_name}` : ''}
                                    </div>
                                </div>
                            </div>
                        </li>
                    })}
                    </ul>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}

export default LeaseLogs