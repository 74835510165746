import React, { useEffect, useState } from "react"
import {Helmet} from "react-helmet"
import { Card, Input, CardBody, Col, Container, InputGroup, Row, Spinner, CardTitle, Badge, Modal } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useLocation, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import { object, string, number } from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { capitalize } from "helpers/stringHelper";
import Layout from "components/VerticalLayout";
import "./datatables.scss"
import "../../assets/scss/manual/manual.scss"
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const Payment = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // const { SearchBar } = Search;
    const [id, setId] = useState('');
    const [accountData, setAccountData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [transactionData, setTransactionData] = useState([]);
    const [addPaymentOpen, setAddPaymentOpen] = useState(false);
    const [addPaymentProcessingOpen, setAddPaymentProcessingOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [originalData, setOriginalData] = useState([]);

    const validationSchema = object().shape({
        status: string().required("status is required"),
    });

    const columns = [
        {
            label:'Payment Date',
            name:'transaction_date',
            options:{
                sort:false,
                customBodyRender: (data)=>{
                    return moment(data).format("MM/DD/YYYY HH:mm")
                }
            }
        },
        {
            label:'Payment Amount',
            name:'amount',
            options:{
                sort:false,
                customBodyRender: (data)=>{
                    return `$${data}`
                }
            }
        },
        {
            label:'Payment Method',
            name:'payment_mode',
            options:{
                sort:false,
                customBodyRender: (data)=>{
                    return data && data.toUpperCase()
                }
            }
        },
        {
            label:'Last 4 Digits',
            name:'last_4',
        },
        {
            label:'No. of Leases',
            name:'no_of_leases',
        },
        {
            label:'Discount Applied',
            name:'discount',
            options:{
                sort:false,
                customBodyRender: (data)=>{
                    return data === 'WEBD10' ? '10%' : data === 'FFAD15' ? '15%' : data === 'RCD20' ? '20%' : data === 'FFD30' ? '30%' : '0%' 
                }
            }
        },
        {
            label:'Notes',
            name:'note',
        },
        {
            label:'Status',
            name:'status',
            options:{
                sort:false,
                customBodyRender: (data)=>{
                    return capitalize(data)
                }
            }
        }
    ]

    const getTransactionData = async() => {
        setLoadingTransactions(true)
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/transaction/listing?customer_id=${accountData.customer_id._id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setTransactionData(data.transData)
                setOriginalData(data.transData)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoadingTransactions(false)
    }

    useEffect(() => {
        if(accountData && accountData.customer_id){
            getTransactionData();
        }
    }, [accountData])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            start_date: '',
            status: "trial",
            is_online: true,
            suspension_start_date: '',
            suspension_end_date: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                values.id = accountData.customer_id._id;
                values.start_date = values.start_date ? moment(values.start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
                values.suspension_start_date = values.suspension_start_date ? moment(values.suspension_start_date).format('YYYY-MM-DD') : '';
                values.suspension_end_date = values.suspension_end_date ? moment(values.suspension_end_date).format('YYYY-MM-DD') : '';
                const { data } = await axios.post(`${AppConfig.baseUrl}/customer/updatePayments`, values, {
                    headers: { token: localStorage.getItem("token") }
                });
                if (!data.error) {
                    formik.resetForm();
                    toast.success('Payment updated successfully');
                    navigate("/accounts")
                }
                else {
                    throw new Error(data.title);
                }
            } catch (error) {
                toast.error(error.message || 'Something went wrong');
            }
            setUpdateOpen(false)
            setLoading(false)
        }
    });

    const addPaymentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: '',
            payment_method: '',
            email: '',
            reference_number: '',
        },
        onSubmit: async (values) => {
            setLoading(true);
            try {
                values.id = accountData.customer_id._id;
                const { data } = await axios.post(`${AppConfig.baseUrl}/customer/addPayment`, values, {
                    headers: { token: localStorage.getItem("token") }
                });
                if (!data.error) {
                    addPaymentForm.resetForm();
                    toast.success('Payment added successfully');
                    navigate("/accounts")
                }
                else {
                    throw new Error(data.title);
                }
            } catch (error) {
                toast.error(error.message || 'Something went wrong');
            }
            setUpdateOpen(false)
            setLoading(false)
        }
    });

    const addPaymentProcessingForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            admin_email: '',
            admin_name: '',
            other_emails: '',
            phone_number: '',
        },
        onSubmit: async (values) => {
            setLoading(true);
            try {
                values.id = accountData.customer_id._id;
                const { data } = await axios.post(`${AppConfig.baseUrl}/customer/addPaymentProcessing`, values, {
                    headers: { token: localStorage.getItem("token") }
                });
                if (!data.error) {
                    addPaymentProcessingForm.resetForm();
                    toast.success('Payment process added successfully');
                    navigate("/accounts")
                }
                else {
                    throw new Error(data.title);
                }
            } catch (error) {
                toast.error(error.message || 'Something went wrong');
            }
            setAddPaymentProcessingOpen(false)
            setLoading(false)
        }
    });
    
    const handleSubmitAddPayment = () =>{
        if(!addPaymentForm.values.amount || addPaymentForm.values.amount < 0){
            addPaymentForm.setFieldError("amount", "Please enter amount greater than 0")
        }
        else if(!addPaymentForm.values.email){
            addPaymentForm.setFieldError("email", "Please enter valid email")
        }
        else if(addPaymentForm.values.payment_method !== 'online' && !addPaymentForm.values.reference_number){
            addPaymentForm.setFieldError("reference_number", "Please enter valid description")
        }
        else {
            setAddPaymentOpen(true)
        }
    }

    const handleSubmitClick = () => {
        if(formik.values.status === 'trial' && (!formik.values.start_date || moment(formik.values.start_date).isBefore(moment().add(1,'day')) || moment(formik.values.start_date).isAfter(moment().add(1,'month')))){
            formik.setFieldError('start_date','Please select valid start date')
        }
        else if(formik.values.status === 'suspended' && !formik.values.suspension_start_date ){
            formik.setFieldError('suspension_start_date','Please select valid suspension start date')
        }
        else if(formik.values.status === 'suspended' && formik.values.suspension_start_date && formik.values.suspension_end_date && moment(formik.values.suspension_end_date).isBefore(moment(formik.values.suspension_start_date).add(1, 'day'))){
            formik.setFieldError('suspension_end_date','Please select valid suspension end date')
        }
        else{
            setUpdateOpen(true)
        }
    }

    const handleSubmitAddProcessing = () => {
        if(!addPaymentProcessingForm.values.admin_name){
            addPaymentProcessingForm.setFieldError('admin_name','Admin name is required')
        }
        else if(!addPaymentProcessingForm.values.admin_email){
            addPaymentProcessingForm.setFieldError('admin_email','Admin email is required')
        }else if(addPaymentProcessingForm.values.phone_number && String(addPaymentProcessingForm.values.phone_number).length != 10){
            addPaymentProcessingForm.setFieldError('phone_number','Please enter valid mobile number')
        }else{
            setAddPaymentProcessingOpen(true)
        }
    }

    const getAccountData = async () => {
        setLoadingData(true);
        try {
            const { data } = await axios.get(`${AppConfig.baseUrl}/customer/getDetails?id=${id}`, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                setAccountData(data.custData)
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            navigate("/accounts")
        }
        setLoadingData(false);
    }

    useEffect(() => {
        setId((location.search).replace('?', ''));
    }, [])

    useEffect(() => {
        if (id) {
            getAccountData();
        }
        // eslint-disable-next-line
    }, [id])

    useEffect(() => {
        if (accountData) {
            formik.setValues({
                start_date: accountData && accountData.customer_id && accountData.customer_id.start_date ? moment.utc(accountData.customer_id.start_date).format("MM/DD/YYYY") : '',
                status: accountData && accountData.customer_id && accountData.customer_id.status && accountData.customer_id.status === 'active' && moment(accountData.customer_id.suspension_start_date).isAfter(moment()) ? 'suspended' : accountData && accountData.customer_id && accountData.customer_id.status || 'trial',
                is_online: accountData && accountData.customer_id && accountData.customer_id.is_online || false,
                suspension_start_date: accountData && accountData.customer_id && accountData.customer_id.suspension_start_date ? moment.utc(accountData.customer_id.suspension_start_date).format("MM/DD/YYYY") : '',
                suspension_end_date: accountData && accountData.customer_id && accountData.customer_id.suspension_end_date ? moment.utc(accountData.customer_id.suspension_end_date).format("MM/DD/YYYY") : '',
            })
            addPaymentForm.setValues({
                amount: accountData && accountData.customer_id && accountData.customer_id.status === 'delinquent' && accountData.customer_id.due_amount ? accountData.customer_id.due_amount : 0,
                payment_method: 'other',
                email: accountData && accountData.admin_email ? accountData.admin_email : 0,
            })
            addPaymentProcessingForm.setValues({
                admin_name:accountData && accountData.customer_id && accountData.customer_id.payment_processing && accountData.customer_id.payment_processing.admin_name ? accountData.customer_id.payment_processing.admin_name :'',
                admin_email:accountData && accountData.customer_id && accountData.customer_id.payment_processing && accountData.customer_id.payment_processing.admin_email ? accountData.customer_id.payment_processing.admin_email :'',
                phone_number:accountData && accountData.customer_id && accountData.customer_id.payment_processing && accountData.customer_id.payment_processing.phone_number ? accountData.customer_id.payment_processing.phone_number :'',
                other_emails:accountData && accountData.customer_id && accountData.customer_id.payment_processing && accountData.customer_id.payment_processing.other_emails ? accountData.customer_id.payment_processing.other_emails.join() :'',
            })
        }
    }, [accountData])

    const handleSearch = (value) => {
        const data = originalData.filter(v => String(v.status || '').match(new RegExp(value,"i")) || String(v.payment_mode || '').match(new RegExp(value,"i")) || String(v.discount === 'WEBD10' ? '10%' : v.discount === 'FFAD15' ? '15%' : v.discount === 'RCD20' ? '20%' : v.discount === 'FFD30' ? '30%' : '0%' || '').match(new RegExp(value,"i")) || String(v.no_of_leases || '').match(new RegExp(value,"i")) || String(v.last_4 || '').match(new RegExp(value,"i")) || String(v.amount || '').match(new RegExp(value,"i")) || String(moment(v.transaction_date).format("MM/DD/YYYY HH:mm") || '').match(new RegExp(value, "i")))
        setTransactionData(data)
    }

    return (
        <Layout>
            <div className="page-content">
               <Helmet>
                    <title>Lease Bean | Payments</title>
               </Helmet>
                <Container fluid={true}>
                    <Breadcrumbs title="Forms" breadcrumbItem={`Payment For ${accountData && accountData.customer_id && accountData.customer_id.company || ''} (${accountData && accountData.phone_number || ''})`} />
                    {
                        loadingData ?
                            <div style={{ textAlign: 'center' }}><Spinner color="primary" className="txt-theme-color" /></div>
                            :
                            <React.Fragment>
                                <Row>
                                    <Col xl="6" lg="6" md="6" xxl="6">
                                        <Card className="overflow-hidden">
                                            <CardBody>
                                                <CardTitle className="mb-4">ACCOUNT DETAILS</CardTitle>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col xs='5'>
                                                        <h6>
                                                            Account Status:
                                                        </h6>
                                                    </Col>
                                                    <Col xs='7'>
                                                        <div>
                                                            {
                                                                accountData && accountData.customer_id && accountData.customer_id.status &&
                                                                <Badge
                                                                    className={"font-size-12 badge-soft-" + accountData.customer_id.status === "trial" ? 'primary' : accountData.customer_id.status === "active" ? 'success' : 'warning'}
                                                                    color={accountData.customer_id.status === "trial" ? 'primary' : accountData.customer_id.status === "active" ? 'success' : accountData.customer_id.status === "delinquent" ? 'danger' : 'warning'}
                                                                    pill
                                                                >
                                                                    {capitalize(accountData.customer_id.status)}
                                                                </Badge>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col xs='5'>
                                                        <h6>
                                                            Service Start Date:
                                                        </h6>
                                                    </Col>
                                                    <Col xs='7'>
                                                        {accountData && accountData.customer_id && accountData.customer_id.start_date && moment.utc(accountData.customer_id.start_date).format("MM/DD/YYYY")}
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </CardBody>
                                        </Card>
                                        <Card className="overflow-hidden">
                                            <CardBody>
                                                <CardTitle className="mb-4">Payment Processing</CardTitle>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col xs='5'>
                                                        <h6>
                                                            Admin name:
                                                        </h6>
                                                    </Col>
                                                    <Col xs='7'>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="admin_name"
                                                        onChange={addPaymentProcessingForm.handleChange}
                                                        onBlur={() => addPaymentProcessingForm.setFieldTouched("admin_name")}
                                                        value={addPaymentProcessingForm.values.admin_name}
                                                    />
                                                    {addPaymentProcessingForm.errors.admin_name && (
                                                        <div className="text-danger">{addPaymentProcessingForm.errors.admin_name}</div>
                                                    )}
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col xs='5'>
                                                        <h6>
                                                            Admin email:
                                                        </h6>
                                                    </Col>
                                                    <Col xs='7'>
                                                    <Input
                                                        type="email"
                                                        className="form-control"
                                                        id="admin_email"
                                                        onChange={addPaymentProcessingForm.handleChange}
                                                        onBlur={() => addPaymentProcessingForm.setFieldTouched("admin_email")}
                                                        value={addPaymentProcessingForm.values.admin_email}
                                                    />
                                                    {addPaymentProcessingForm.errors.admin_email && (
                                                        <div className="text-danger">{addPaymentProcessingForm.errors.admin_email}</div>
                                                    )}
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col xs='5'>
                                                        <h6>
                                                            Admin number:
                                                        </h6>
                                                    </Col>
                                                    <Col xs='7'>
                                                    <Input
                                                        type="number"
                                                        className="form-control"
                                                        id="phone_number"
                                                        onChange={addPaymentProcessingForm.handleChange}
                                                        onBlur={() => addPaymentProcessingForm.setFieldTouched("phone_number")}
                                                        value={addPaymentProcessingForm.values.phone_number}
                                                    />
                                                    {addPaymentProcessingForm.errors.phone_number && (
                                                        <div className="text-danger">{addPaymentProcessingForm.errors.phone_number}</div>
                                                    )}
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col xs='5'>
                                                        <h6>
                                                            Others receiving payment,invoicing and account status emails:
                                                        </h6>
                                                    </Col>
                                                    <Col xs='7'>
                                                    <Input
                                                        type="textarea"
                                                        rows={2}
                                                        className="form-control"
                                                        id="other_emails"
                                                        onChange={addPaymentProcessingForm.handleChange}
                                                        onBlur={() => addPaymentProcessingForm.setFieldTouched("other_emails")}
                                                        value={addPaymentProcessingForm.values.other_emails}
                                                    />
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <button type="button" onClick={handleSubmitAddProcessing} className="btn bg-theme-color w-md" disabled={accountData && accountData.customer_id && accountData.customer_id.is_deleted === true}>
                                                    SUBMIT
                                                </button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl="6" lg="6" md="6" xxl="6">
                                        <Card className="overflow-hidden">
                                            <CardBody>
                                                <CardTitle className="mb-4">MANUAL PAYMENT</CardTitle>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col xs='5'>
                                                        <h6>
                                                            Payment Amount:
                                                        </h6>
                                                    </Col>
                                                    <Col xs='7'>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="amount"
                                                            onChange={addPaymentForm.handleChange}
                                                            onBlur={() => addPaymentForm.setFieldTouched("amount")}
                                                            value={addPaymentForm.values.amount}
                                                        />
                                                        {addPaymentForm.errors.amount && (
                                                            <div className="text-danger">{addPaymentForm.errors.amount}</div>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col xs='5'>
                                                        <h6>
                                                            Payment Method:
                                                        </h6>
                                                    </Col>
                                                    <Col xs='7'>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="payment_method"
                                                                id="online"
                                                                value="online"
                                                                checked={addPaymentForm.values.payment_method === 'online'}
                                                                onChange={addPaymentForm.handleChange}
                                                                onBlur={() => addPaymentForm.setFieldTouched("payment_method")}
                                                            />
                                                            <label
                                                                className="form-check-label radioLabel"
                                                                htmlFor="online"
                                                            >
                                                                ACH/Credit Card
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="payment_method"
                                                                id="other"
                                                                value="other"
                                                                checked={addPaymentForm.values.payment_method === 'other'}
                                                                onChange={addPaymentForm.handleChange}
                                                                onBlur={() => addPaymentForm.setFieldTouched("payment_method")}
                                                            />
                                                            <label
                                                                className="form-check-label radioLabel"
                                                                htmlFor="other"
                                                            >
                                                                Other Payment Method
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col xs='5'>
                                                        <h6>
                                                            Description:
                                                        </h6>
                                                    </Col>
                                                    <Col xs='7'>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="reference_number"
                                                            onChange={addPaymentForm.handleChange}
                                                            onBlur={() => addPaymentForm.setFieldTouched("reference_number")}
                                                            value={addPaymentForm.values.reference_number}
                                                        />
                                                        {addPaymentForm.errors.reference_number && (
                                                            <div className="text-danger">{addPaymentForm.errors.reference_number}</div>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <button type="button" onClick={handleSubmitAddPayment} className="btn bg-theme-color w-md" disabled={accountData && accountData.customer_id && accountData.customer_id.is_deleted === true}>
                                                    SUBMIT
                                                </button>
                                            </CardBody>
                                        </Card>
                                        <Card className="overflow-hidden">
                                            <CardBody>
                                                <CardTitle className="mb-4">ACCOUNT UPDATE</CardTitle>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col xs='5'>
                                                        <h6>
                                                            Account Status:
                                                        </h6>
                                                    </Col>
                                                    <Col xs='7'>
                                                        <select
                                                            name = "status"
                                                            className={`drp text-${formik.values.status === 'active' ? 'success' : formik.values.status === 'trial' ? 'primary' : formik.values.status === 'delinquent' ? 'danger' : 'warning'}`}
                                                            style={{ border: 'none', width: 'auto', fontWeight: 'bold'}}
                                                            onChange={formik.handleChange}
                                                        >
                                                            <option name="trial" value='trial' selected={formik.values.status === "trial"}>TRIAL</option>
                                                            <option name="active" value='active' selected={formik.values.status === "active"}>ACTIVE</option>
                                                            <option name="suspended" value='suspended' selected={formik.values.status === "suspended"}>SUSPENDED</option>
                                                            <option name="delinquent" value='delinquent' selected={formik.values.status === "delinquent"}>DELINQUENT</option>
                                                        </select>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row className="mb-3">
                                                    <Col xs='5'>
                                                        <h6>
                                                            Online payment:
                                                        </h6>
                                                    </Col>
                                                    <Col xs='7'>
                                                        <InputGroup>
                                                            <div className="form-check form-switch form-switch-lg" style={{ alignContent: 'center' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="is_online"
                                                                    checked={formik.values.is_online}
                                                                    disabled={loading}
                                                                    onChange={formik.handleChange}
                                                                />
                                                                {
                                                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                                }
                                                            </div>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                {
                                                    formik.values.is_online && accountData && accountData.customer_id && accountData.customer_id.stripe_cust_id && accountData.customer_id.stripe_payment_method_id &&
                                                    <>                                               
                                                    <Row className="mb-3">
                                                        <Col xs='5'>
                                                            <h6>
                                                                Payment method:
                                                            </h6>
                                                        </Col>
                                                        <Col xs='7'>
                                                            {accountData && accountData.customer_id && accountData.customer_id.card_no ? 'Card ' + accountData.customer_id.card_no : 'ACH ' + accountData.customer_id.bank_acc_no}
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    </>
                                                }
                                                {formik.values.status === 'trial' &&
                                                    <>
                                                    <Row className="mb-3">
                                                        <Col xs='5'>
                                                            <h6>
                                                                Start Date:
                                                            </h6>
                                                        </Col>
                                                        <Col xs='7'>
                                                            <InputGroup>
                                                                <Flatpickr
                                                                    onClose={([date]) => {
                                                                        formik.setFieldValue("start_date", date ? moment(date).format('MM/DD/YYYY') : "")
                                                                    }}
                                                                    name="start_date"
                                                                    onBlur={() => formik.setFieldTouched("start_date")}
                                                                    value={formik.values.start_date}
                                                                    className="form-control d-block bg-white"
                                                                    options={{
                                                                        dateFormat: "m/d/Y",
                                                                        allowInput: true,
                                                                        minDate: moment().add(1, 'days').format('MM/DD/YYYY'),
                                                                        maxDate: moment().add(1, 'month').format('MM/DD/YYYY')
                                                                    }}
                                                                />
                                                                <div className="input-group-append">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                        disabled
                                                                    >
                                                                        <i
                                                                            className="fa fa-calendar"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </InputGroup>
                                                                {formik.errors.start_date && (
                                                                    <div className="text-danger">{formik.errors.start_date}</div>
                                                                )}
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                    </>
                                                }
                                                {(formik.values.status === 'suspended' || (formik.values.status === 'active' && formik.values.suspension_start_date && moment(formik.values.suspension_start_date).isAfter(moment())) )&&
                                                    <>
                                                        <Row className="mb-3">
                                                            <Col xs='5'>
                                                                <h6>
                                                                    Suspension Start Date:
                                                                </h6>
                                                            </Col>
                                                            <Col xs='7'>
                                                                <InputGroup>
                                                                    <Flatpickr
                                                                        onClose={([date]) => {
                                                                            formik.setFieldValue("suspension_start_date", date ? moment(date).format('MM/DD/YYYY') : "")
                                                                            if(moment(date).format('MM/DD/YYYY') !== formik.values.suspension_start_date){
                                                                                formik.setFieldValue("suspension_end_date", "")
                                                                            }
                                                                        }}
                                                                        name="suspension_start_date"
                                                                        onBlur={() => formik.setFieldTouched("suspension_start_date")}
                                                                        value={formik.values.suspension_start_date}
                                                                        className="form-control d-block bg-white"
                                                                        options={{
                                                                            dateFormat: "m/d/Y",
                                                                            allowInput: true,
                                                                        }}
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                            disabled
                                                                        >
                                                                            <i
                                                                                className="fa fa-calendar"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </InputGroup>
                                                                    {formik.errors.suspension_start_date && (
                                                                        <div className="text-danger">{formik.errors.suspension_start_date}</div>
                                                                    )}
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className="mb-3">
                                                            <Col xs='5'>
                                                                <h6>
                                                                    Suspension End Date:
                                                                </h6>
                                                            </Col>
                                                            <Col xs='7'>
                                                                <InputGroup>
                                                                    <Flatpickr
                                                                        onClose={([date]) => {
                                                                            formik.setFieldValue("suspension_end_date", date ? moment(date).format('MM/DD/YYYY') : "")
                                                                        }}
                                                                        name="suspension_end_date"
                                                                        onBlur={() => formik.setFieldTouched("suspension_end_date")}
                                                                        value={formik.values.suspension_end_date}
                                                                        className="form-control d-block bg-white"
                                                                        options={{
                                                                            dateFormat: "m/d/Y",
                                                                            allowInput: true,
                                                                            minDate: moment(formik.values.suspension_start_date).add(1, 'days').format('MM/DD/YYYY'),
                                                                        }}
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                            disabled
                                                                        >
                                                                            <i
                                                                                className="fa fa-calendar"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </InputGroup>
                                                                    {formik.errors.suspension_end_date && (
                                                                        <div className="text-danger">{formik.errors.suspension_end_date}</div>
                                                                    )}
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                    </>
                                                }
                                                <button type="button" onClick={handleSubmitClick} className="btn bg-theme-color w-md" disabled={accountData && accountData.customer_id && accountData.customer_id.is_deleted === true}>
                                                    SUBMIT
                                                </button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card className="overflow-hidden">
                                            <CardBody>
                                                <CardTitle className="mb-4">TRANSACTIONS</CardTitle>
                                                <hr />
                                                <ThemeProvider theme={createTheme({ components: getMuiTheme() })}>
                                                    <MUIDataTable
                                                        title={
                                                            <Row className="row">
                                                                <Col md="4" className="pr-0">
                                                                    <div className="me-2 mb-3">
                                                                        <div className="position-relative sw">
                                                                            <Input
                                                                                placeholder="Search"
                                                                                type="text"
                                                                                value={search}
                                                                                onChange={(e) => { handleSearch(e.target.value); setSearch(e.target.value) }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md="9"></Col>
                                                            </Row>
                                                        }
                                                        data={transactionData}
                                                        columns={columns}
                                                        options={{
                                                            rowsPerPage: 50,
                                                            count: transactionData.length,
                                                            print: false,
                                                            download: false,
                                                            filter: false,
                                                            selectableRows: "none",
                                                            search: false,
                                                            rowsPerPageOptions: [],
                                                            responsive: 'scroll',
                                                            textLabels: {
                                                                body: {
                                                                    noMatch: loadingTransactions ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                                                },
                                                                viewColumns: {
                                                                    title: "",
                                                                },
                                                            }
                                                        }}
                                                    />
                                                </ThemeProvider>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </React.Fragment>
                    }
                </Container>
            </div>
            <Modal
                isOpen={updateOpen}
                toggle={() => {
                    setUpdateOpen(!updateOpen)
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Update Payments</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setUpdateOpen(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="mb-3">
                            {
                                ((formik.values.status === 'trial' && accountData.customer_id && (accountData.customer_id.status === 'active' || accountData.customer_id.status === 'suspended' || accountData.customer_id.status === 'delinquent')) || (formik.values.status === 'active' && accountData.customer_id && (accountData.customer_id.status === 'suspended' || accountData.customer_id.status === 'delinquent'))) ? 
                                    `Reminder: The outstanding balance on the customer’s account is ${ accountData.customer_id && accountData.customer_id.due_amount ? accountData.customer_id.due_amount : 0}. Once you move the status to active, this amount will be reset to Zero. Do you want to Proceed ?` : 
                                (formik.values.is_online && (accountData.customer_id && !accountData.customer_id.is_online)) ? 
                                    `Reminder: The outstanding balance on the customer’s account is ${ accountData.customer_id && accountData.customer_id.due_amount ? accountData.customer_id.due_amount : 0}. Once you change the payment method to online, this amount will be reset to Zero. Do you want to Proceed ?` :
                                "Are you sure you want to update this account ?"
                            }
                        </div>
                        <div className="modal-footer mt-0">
                            <button
                                type="button"
                                className="btn bg-theme-color"
                                onClick={() =>
                                    setUpdateOpen(false)
                                }
                                disabled={loading}
                            >
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                No
                            </button>
                            <button type="submit" className="btn bg-theme-color" disabled={loading}>
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                Yes
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                isOpen={addPaymentOpen}
                toggle={() => {
                    setAddPaymentOpen(!addPaymentOpen)
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Add Payment</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setAddPaymentOpen(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={addPaymentForm.handleSubmit}>
                        <div className="mb-3"> 
                            Are you sure you want to add payment to this account ?
                        </div>
                        <div className="modal-footer mt-0">
                            <button
                                type="button"
                                className="btn bg-theme-color"
                                onClick={() =>
                                    setAddPaymentOpen(false)
                                }
                                disabled={loading}
                            >
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                No
                            </button>
                            <button type="submit" className="btn bg-theme-color" disabled={loading}>
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                Yes
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal
                isOpen={addPaymentProcessingOpen}
                toggle={() => {
                    setAddPaymentProcessingOpen(!addPaymentProcessingOpen)
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Update Payment Processing Details</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setAddPaymentProcessingOpen(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={addPaymentProcessingForm.handleSubmit}>
                        <div className="mb-3"> 
                            Are you sure you want to update payment processing details for this account ?
                        </div>
                        <div className="modal-footer mt-0">
                            <button
                                type="button"
                                className="btn bg-theme-color"
                                onClick={() =>
                                    setAddPaymentProcessingOpen(false)
                                }
                                disabled={loading}
                            >
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                No
                            </button>
                            <button type="submit" className="btn bg-theme-color" disabled={loading}>
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                }
                                Yes
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </Layout>
    )
}

export default Payment