import React from "react";
import logoWhite from "assets/images/logos/logo-long.png";

class Success extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: ''
        };
    }

    componentDidMount(){
        const search = window.location.search.replace('?', '');
        this.setState({type: search})
    }

    render() {
        return (
            <React.Fragment>
                
                <div className="main">
                    <section className="page-header-section ptb-100 bg-image full-height" image-overlay="8">
                        <div className="background-image-wraper" style={{backgroundImage: "url(background.jpg)",opacity:0.4}}></div>
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12">
                                    <div className="hero-content-left text-black">
                                    <br/><br/><br/><br/><br/>
                                            {
                                            this.state.type === 'payment' || this.state.type === 'microdeposit' || this.state.type === 'payment_method' ?
                                                <h2 className="text-black">Thank You!</h2>
                                            : <h2 className="text-black">WECLOME TO LEASEJAVA!</h2>
                                            }
                                        <br/>
                                        <p className="lead">
                                            {
                                            this.state.type === 'payment' ?
                                                <>Your payment was successful. LeaseJava has recorded your payment method for future payments and will charge you at the end of every month depending on your usage.</>
                                                :
                                            this.state.type === 'microdeposit' ?
                                                <>
                                                    To validate your ACH account, 
                                                    Stripe will make a micro-deposit of $0.01 to your account in 1-2 business days. 
                                                    Please find the validation code in your bank account statement under this transaction that looks like - SMXXXX. 
                                                    <br/>
                                                    Please use this code to validate your ACH account by following the steps mentioned in an email you will get from us.
                                                </>
                                            : this.state.type === 'payment_method' ?
                                                <>
                                                    We have recorded your payment method.
                                                    To validate your ACH account, 
                                                    Stripe will make a micro-deposit of $0.01 to your account in 1-2 business days. 
                                                    Please find the validation code in your bank account statement under this transaction that looks like - SMXXXX. 
                                                    <br/>
                                                    Please use this code to validate your ACH account by following the steps mentioned in an email you will get from us.
                                                </>
                                            :
                                                <>
                                                    <p>Your registration is successful! Thank you registering with LeaseJava. You should receive an e-mail with the login credentials shortly.</p>
                                                    <br/>
                                                    <p>Note: If you didn’t receive an e-mail, please check your Spam folder. If the e-mail is not found in the Spam folder as well, please contact your IT department to check and ensure that Leasejava.com e-mail is not blocked by their e-mail filters.</p>
                                                    <br/>
                                                    <p>For any further questions/issues – please feel free to contact us at support@leasejava.com. We would be happy to assist you!</p>
                                                </>
                                            }
                                        </p>
                                        <br/>
                                        <p className="lead">
                                            Thank you!
                                            <br/>
                                            LeaseJava Team
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                      </div>
                    </section>
                </div>
               
            </React.Fragment>
        );
    }
}

export default Success;
