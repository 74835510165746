import React, { useEffect, useState } from "react"
import {Helmet} from "react-helmet";
import { Container, Row, Col, Card, CardTitle, CardBody, InputGroup, Button, Table, Modal} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Breadcrumb from "../../components/Common/Breadcrumb";
import LineChart from "../../components/Common/LineChart";
import { Link } from "react-router-dom";
import AccountsTable from "./accountsTable";
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import moment from "moment";
import SimpleBar from "simplebar-react"
import { commaSeperatedNumber } from "helpers/stringHelper";
import Layout from "components/VerticalLayout";

const Dashboard = () => {
  const [accountsData, setAccountsData] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [loadingRevenue, setLoadingRevenue] = useState(false);
  const [startDate, setStartDate] = useState(moment().startOf('month').format('MM/DD/YYYY'));
  const [endDate, setEndDate] = useState(moment().format('MM/DD/YYYY'));
  const [selectedFormat, setSelectedFormat] = useState();
  const [revenueData, setRevenueData] = useState({});
  const [labels, setLabels] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [leaseSummary,setLeaseSummary] = useState([])
  const [leaseData,setLeaseData] = useState([])
  const [heighestRevenue, setHeighestRevenue] = useState({})
  const [lowestRevenue, setLowestRevenue] = useState({})
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [fullLabels, setFullLabels] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [downloadingSummary, setDownloadingSummary] = useState(false);

  const columns = [
    {
      name: "customer_id.company",
      label: "Organization",
      options:{
        setCellProps: () => ({style: {minWidth: '100px',height: '50px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        customBodyRenderLite: (dataIndex, rowIndex) => (<>{accountsData && accountsData[dataIndex] && accountsData[dataIndex].customer_id && accountsData[dataIndex].customer_id.company ? accountsData[dataIndex].customer_id.company : ""}</>)
      } 
    },
    {
      name: "contact_person",
      label: "Contact Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "phone_number",
      label: "Phone",
    },
    {
      name: "customer_id.discount",
      label: "Discount",
      options:{
        customBodyRenderLite: (dataIndex, rowIndex) => (<>{accountsData && accountsData[dataIndex] && accountsData[dataIndex].customer_id && accountsData[dataIndex].customer_id.discount && accountsData[dataIndex].customer_id.discount !== 'D0' ? accountsData[dataIndex].customer_id.discount : ""}</>)
      }      
    },
    {
      name: "action",
      label: "Action",
      // eslint-disable-next-line react/display-name
      options:{
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <Link to={{pathname:'/accounts/details', search: accountsData && accountsData[dataIndex]._id}} className="text-white">
            <Button className="btn btn-sm bg-theme-color text-white" style={{marginTop: '-5px'}}>
              Details
            </Button>
          </Link>
        ),
      }
    },
  ]
  
  const handleChangeCustomer = async(e) => {
    const custData = await allCustomers.filter(val => val.customer_id.company === e.target.value);
    setSelectedCustomer(custData[0] && custData[0].customer_id ? custData[0].customer_id : {});
  }

  const getAccountsData = async() => {
    setLoadingAccounts(true);
    try {
      const {data} = await axios.get(`${AppConfig.baseUrl}/customer/listing`,{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        setAccountsData(data.custData || []);
        setAllCustomers(data.custData || []);
      }
      else{
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoadingAccounts(false);
  }

  const getRevenueData = async(e) => {
    if(e){
      e.preventDefault();
    }
    setRevenueData({});
    setLoadingRevenue(true);
    try {
      const {data} = await axios.post(`${AppConfig.baseUrl}/customer/get_revenue_data`,{startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD')},{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        setRevenueData(data.revenueData || {});
      }
      else{
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoadingRevenue(false);
  }

  const getCustomerData = async() => {
    try{
      const {data} = await axios.get(`${AppConfig.baseUrl}/customer/get_lease_summary?id=${selectedCustomer._id}`,{
        headers: { token: localStorage.getItem("token") }
      });
      if(data && !data.error){
        setLeaseSummary(data.leaseDataByClient);
        setLeaseData(data.leaseData);
      }else{
        throw new Error(data.title);
      }
    }
    catch(error){
      toast.error(error.message || 'Something went wrong');
    }
  }

  useEffect(() => {
    if(revenueData.custData && revenueData.custData[0] && revenueData.custData[0].customerData && revenueData.custData[0].customerData.length > 0){
      let label = []
      let full_label = []
      let data = []
      for(const r of revenueData.custData[0].customerData){
        full_label.push(r.custName)
        label.push(getInitials(r.custName))
        data.push(r && r.total ? Number((r.total).toFixed(2)) : 0) 
      }
      setFullLabels(full_label)
      setLabels(label);
      setGraphData(data);
      setLowestRevenue(revenueData.custData[0].customerData[0])
      setHeighestRevenue(revenueData.custData[0].customerData[revenueData.custData[0].customerData.length-1])
    }
    else{
      setGraphData([])
      setLabels([])
      setFullLabels([])
      setLowestRevenue({})
      setHeighestRevenue({})
    }
    if(revenueData.custData && revenueData.custData[0] && revenueData.custData[0]._id){
      setTotalRevenue(revenueData.custData[0]._id.total)
    }else{
      setTotalRevenue(0)
    }
  },[revenueData])

  function getInitials(label){
      const a = label.split(' ')
      let b =''
      for(const i of a){
          b = b + '' + i.charAt(0)
      }
      return b
  }
  
  useEffect(() => {
    if(selectedFormat){
      setStartDate(moment().subtract(1, selectedFormat).startOf(selectedFormat).format('MM/DD/YYYY'))
      setEndDate(moment().subtract(1, selectedFormat).endOf(selectedFormat).format('MM/DD/YYYY'))
    }
  },[selectedFormat])

  useEffect(() => {
    getAccountsData();
    getRevenueData();
  },[])

  useEffect(() => {
    if(Object.keys(selectedCustomer).length > 0){
      getCustomerData();
    }
    else{
      setLeaseSummary([])
      setLeaseData([])
    }
  }, [selectedCustomer])

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  }

  const handleClosePopup = () => {
    setIsOpen(false);
  }

  const downloadLeaseSummary = () => {
    try {
      setDownloadingSummary(true)
      let csv_data = [];
      csv_data.push(',Active,Terminated (1/4th of an Active Leases),Expired (1/4th of an Active Leases),Drafted (1/4th of an Active Leases),Total')
      for (const s of leaseSummary) {
        csv_data.push(`\"${s.clientName}\",${s.activeLeases},${s.terminatedLeases},${s.expiredLeases},${s.draftedLeases},${s.activeLeases + (s.terminatedLeases > 0 ? s.terminatedLeases/4 : 0) + (s.expiredLeases > 0 ? s.expiredLeases/4 : 0) + (s.draftedLeases > 0 ? s.draftedLeases/4 : 0)}`)
      }
      csv_data.push(`,,,,Total Leases,${leaseData && leaseData[0] ?(leaseData[0].activeLeases + (leaseData[0].terminatedLeases > 0 ? leaseData[0].terminatedLeases/4 : 0) + (leaseData[0].expiredLeases > 0 ? leaseData[0].expiredLeases/4 : 0) + (leaseData[0].draftedLeases > 0 ? leaseData[0].draftedLeases/4 : 0)) : 0}`)
      csv_data = csv_data.join('\n');
      let CSVFile = new Blob([csv_data], {
        type: "text/csv"
      });
      var temp_link = document.createElement('a');
      temp_link.download = "lease_summary.csv";
      var url = window.URL.createObjectURL(CSVFile);
      temp_link.href = url;
      temp_link.style.display = "none";
      document.body.appendChild(temp_link);
      temp_link.click();
      document.body.removeChild(temp_link);
      toast.success('CSV downloaded successfully');
      setDownloadingSummary(false)
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
  }

  return (
    <Layout>
      <div className="page-content">
       <Helmet>
          <title>LeaseJava | Dashboard</title>
       </Helmet>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Dashboard" breadcrumbItem="Dashboard"
          />
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <div className="mt-1">
                  Selected Dates: {startDate} To {endDate}
                </div>
                <i className='bx bx-cog' style={{fontSize:'25px'}} onClick={() => setIsOpen(true)}></i>
              </div>
            </CardBody>
          </Card>
          <Row>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Highest revenue</p>
                      <h5 className="mb-0">{heighestRevenue.total ? (heighestRevenue.total).toFixed(2) : 0}</h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className='bx bx-up-arrow-alt'></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardBody className="border-top py-2"><div className="bg-white">{heighestRevenue.custName}</div></CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Lowest Revenue</p>
                      <h5 className="mb-0">{lowestRevenue.total ? (lowestRevenue.total).toFixed(2) : 0}</h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className='bx bx-down-arrow-alt'></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardBody className="border-top py-2"><div className="bg-white">{lowestRevenue.custName}</div></CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Total Revenue</p>
                      <h5 className="mb-0">{totalRevenue ? (totalRevenue).toFixed(2) : 0}</h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className='bx bx-dollar'></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardBody className="border-top py-2"><div className="bg-white">Total</div></CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-3">
                      <p className="text-muted mb-2">Highest revenue</p>
                      <h5 className="mb-0">120</h5>
                    </div>
                    <div className="avatar-sm ms-auto">
                      <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                        <i className="bx bxs-book-bookmark"></i>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardBody className="border-top py-2"><div className="bg-white">Customer</div></CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <Card>
                  <CardBody>
                    <CardTitle className="card-title mb-4">REVENUE</CardTitle>
                    <SimpleBar style={{ height: "315px" }}>
                      <div className="table-responsive">
                        <Table className="table align-middle table-nowrap">
                          <tbody>
                            <tr>
                              <th>
                                <h5>Account</h5>
                              </th>
                              <th>
                                <h5>Date</h5>
                              </th>
                              <th className="text-end">
                                <h5>Amount</h5>
                              </th>
                            </tr>
                            {
                              revenueData && revenueData.custTransData && revenueData.custTransData.length > 0 ? 
                                revenueData.custTransData.map((val, i) => {
                                  return <tr key={i}>
                                    <td>
                                      <div>
                                        <h6 className="font-size-14 mb-1">{val.company || ''}</h6>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <h6 className="font-size-14 mb-1">{val.transaction_date ? moment(val.transaction_date).format('MM/DD/YYYY') : ''}</h6>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-end">
                                        <h6 className="font-size-14 mb-0">{val.amount ? commaSeperatedNumber(val.amount) : 0}</h6>
                                      </div>
                                    </td>
                                  </tr>
                                })
                              :
                                <tr>
                                  <td colSpan={3}>
                                    <div className="text-center">No data found</div>
                                  </td>
                                </tr>
                            }
                          </tbody>
                        </Table>
                      </div>
                    </SimpleBar>
                  </CardBody>
                </Card>
            </Col>
            <Col md={6}>
            <Card>
                <CardBody>
                  <LineChart labels={labels} data={graphData} height={348} fullLabels={fullLabels}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                    <div className="me-2">
                      <h5 className="card-title" style={{display:'inline-block'}}>Lease Summary</h5>
                      <Button 
                        className="btn btn-sm bg-theme-color ms-2 float-end" 
                        disabled={!leaseSummary || (leaseSummary && leaseSummary.length < 1) || downloadingSummary}
                        onClick={downloadLeaseSummary}
                      >
                        {
                          downloadingSummary && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                        }
                        EXPORT CSV
                      </Button>
                      <select onChange={handleChangeCustomer} className="form-select form-select-sm" style={{width:'140px',display:'inline-block',float:'right'}}>
                        <option value="">Customer Name</option>
                      {
                        allCustomers.map(val => {
                          return (<option key={val.customer_id._id} value={val.customer_id.company} >{val.customer_id.company}</option>)
                        })
                      }
                      </select>
                    </div>
                  <div className="table-responsive mt-4"  style={{height:'300px'}}>
                    <table className="table align-middle table-nowrap mb-0">
                      <thead style={{backgroundColor:'aliceblue'}}>
                        <tr>
                          <th scope="col" colSpan={1}></th>
                          <th scope="col" style={{paddingBottom:'26px'}}>Active</th>
                          <th scope="col" style={{paddingTop:'18px'}}>Terminated<h6 style={{fontSize:'10px',marginTop:'5px'}}>(1/4<sup>th</sup> of an Active Leases)</h6></th>
                          <th scope="col">Expired<h6 style={{fontSize:'10px',marginTop:'5px'}}>(1/4<sup>th</sup> of an Active Leases)</h6></th>
                          <th scope="col">Drafted<h6 style={{fontSize:'10px',marginTop:'5px'}}>(1/4<sup>th</sup> of an Active Leases)</h6></th>
                          <th scope="col" style={{paddingBottom:'26px'}}>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                      {leaseSummary.map((i, key) => (
                              <tr key={key}>
                                <td>
                                  {i.clientName}
                                </td>
                                <td>
                                  {i.activeLeases}
                                </td>
                                <td>
                                  {i.terminatedLeases}
                                </td>
                                <td>
                                  {i.expiredLeases}
                                </td>
                                <td>
                                  {i.draftedLeases}
                                </td>
                                <td>
                                  {(i.activeLeases + (i.terminatedLeases > 0 ? i.terminatedLeases/4 : 0) + (i.expiredLeases > 0 ? i.expiredLeases/4 : 0) + (i.draftedLeases > 0 ? i.draftedLeases/4 : 0)) || 0}
                                </td>
                              </tr>
                            ))}
                      </tbody>
                      <tfoot style={{position:'sticky',bottom :0,backgroundColor:'aliceblue'}}>
                        <tr>
                        <td style={{textAlign:'end'}} colSpan={5}><h6 style={{marginTop:'10px'}}>Total Leases</h6></td>
                        <td>{leaseData && leaseData[0] ? (leaseData[0].activeLeases + (leaseData[0].terminatedLeases > 0 ? leaseData[0].terminatedLeases/4 : 0) + (leaseData[0].expiredLeases > 0 ? leaseData[0].expiredLeases/4 : 0) + (leaseData[0].draftedLeases > 0 ? leaseData[0].draftedLeases/4 : 0)) : 0} </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="clearfix">
                    <AccountsTable accountsData={accountsData} columns={columns} loading={loadingAccounts}/>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
          isOpen={isOpen}
          toggle={() => {
              toggleOpen();
          }}
          centered
      >
        <div className="modal-header">
            <button
                type="button"
                onClick={() => {
                    handleClosePopup()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <form onSubmit={(e) => {
              getRevenueData(e)
              handleClosePopup()
            }}>
              <Row>
                <div className="d-flex justify-content-between">
                  <div className="form-check form-check-left form-radio-lbsuperadmin form mt-2 radio-btn-p">
                    <input
                      type="radio"
                      id="radio1"
                      name="radios"
                      value='week'
                      onChange={(e) => setSelectedFormat(e.target.value)}
                      className="form-check-input"
                      checked={selectedFormat === 'week'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="radio1"
                    >
                      LAST WEEK
                    </label>
                  </div>
                  <div className="form-check form-check-left form-radio-lbsuperadmin form mt-2 radio-btn-p">
                    <input
                      type="radio"
                      id="radio2"
                      name="radios"
                      value='month'
                      onChange={(e) => setSelectedFormat(e.target.value)}
                      className="form-check-input"
                      checked={selectedFormat === 'month'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="radio2"
                    >
                      LAST MONTH
                    </label>
                  </div>
                  <div className="form-check form-check-left form-radio-lbsuperadmin form mt-2 radio-btn-p">
                    <input
                      type="radio"
                      id="radio3"
                      name="radios"
                      value='year'
                      className="form-check-input"
                      onChange={(e) => setSelectedFormat(e.target.value)}
                      checked={selectedFormat === 'year'}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="radio3"
                    >
                      LAST YEAR
                    </label>
                  </div>
                </div>
              </Row>
              <Row className="mt-4">
                <InputGroup>
                  <Flatpickr
                    value={startDate}
                    className="form-control d-block bg-white"
                    placeholder="START DATE"
                    options={{
                      allowInput: true,
                      altInput: true,
                      altFormat: "m/d/Y",
                      dateFormat: "m/d/Y"
                    }}
                    onClose={([date]) => {
                      setSelectedFormat('')
                      setStartDate(moment(date).format('MM/DD/YYYY'))
                      setEndDate('')
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-outline-secondary docs-datepicker-trigger"
                      disabled
                    >
                      <i
                        className="fa fa-calendar"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </InputGroup>
              </Row>
              <Row className="mt-4 mb-4">
                <InputGroup>
                  <Flatpickr
                    value={endDate}
                    className="form-control d-block bg-white"
                    placeholder="END DATE"
                    options={{
                      allowInput: true,
                      altInput: true,
                      altFormat: "m/d/Y",
                      dateFormat: "m/d/Y",
                      maxDate: startDate && moment(startDate).add(1, 'year').isBefore(moment()) ? new Date(moment(startDate).add(1, 'year')) : new Date(),
                      minDate: startDate ? startDate : ''
                    }}
                    onClose={([date]) => {
                      setSelectedFormat('')
                      setEndDate(moment(date).format('MM/DD/YYYY'))
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-outline-secondary docs-datepicker-trigger"
                      disabled
                    >
                      <i
                        className="fa fa-calendar"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </InputGroup>
              </Row>
              <hr/>
              <Row className="mt-4 mb-1">
                <div className="form-check form-check-left form-radio-lbsuperadmin form">
                  <button type="submit" className="btn bg-theme-color w-md" disabled={loadingRevenue}>
                    {
                      loadingRevenue && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                    }
                    SUBMIT
                  </button>
                </div>
              </Row>
            </form>
        </div>
      </Modal>
    </Layout>
  )
}

export default Dashboard;
