import React ,{useEffect, useState}from "react"
import {  Col, Row ,InputGroup, Card, CardBody, Button, Spinner} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import Breadcrumbs from "../../components/Common/BreadcrumbAdd"
import {Helmet} from "react-helmet"
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import ActionDropdown from "./actionDropdown";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";

const BillingLogs = () => {
    const [startDate, setStartDate] = useState('');
    const [downloading, setDownloading] = useState(false)
    const [billData, setBillData] = useState([]);
    const [loadingBills, setLoadingBills] = useState(false);
    const monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    const columns = [
        {
            name : "customer_id.company",
            label : "Customer",
            options:{
                setCellProps: () => ({style: {minWidth: '100px', height: '60px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
                customBodyRenderLite: (dataIndex) => (billData && billData[dataIndex] && billData[dataIndex].customer_id && billData[dataIndex].customer_id.company)
            }
        },
        {
            name : "month",
            label : "Month",
            options:{
                customBodyRenderLite: (dataIndex) =>(<>{billData && billData[dataIndex] && billData[dataIndex].month  && billData[dataIndex].year ? monthArray[billData[dataIndex].month-1]+", "+billData[dataIndex].year : ''}</>)
            }
        },
        {
            name : "discount_code",
            label : "Discount",
        },
        {
            name : "total_leases",
            label : "Total Leases", 
        },
        {
            name : "is_online",
            label : "Mode",
            options:{
                customBodyRender: (row) => (<>{row && row == true ? "Auto" : "Manual"}</>),
            }
        },
        {
            name : "charged_amount",
            label : "Amount",
            options:{
                customBodyRender: (row) => (<>{row ? Number(row).toFixed(2) : ""}</>)
            }
        },
        {
            name : "invoice",
            label: "Invoice",
            options:{
                customBodyRenderLite: (dataIndex) => (<>{
                    billData && billData[dataIndex] && billData[dataIndex].receipt_url ? 
                        <ActionDropdown selectedLog={billData && billData[dataIndex]} download={downloadInvoice}/> 
                    :
                        <Button onClick={() => downloadInvoice((billData && billData[dataIndex] && billData[dataIndex].invoice_url), "Invoice")} disabled={downloading} className="btn btn-sm bg-theme-color text-white" style={{marginTop:"-5px"}}>
                            Download
                        </Button>
                }</>)
            }
        }
    ]

    const downloadInvoice = async(url, type) => {
        setDownloading(true);
        try {
            if(url){
                const link = document.createElement('a');
                link.href = url;
                link.download = true
                link.target = "_blank"
                document.body.appendChild(link);
                link.click();
                toast.success(`${type} downloaded successfully`);
            }
            else{
                toast.error('Invoice not found');
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setDownloading(false)
    }
  
    const getBillingData = async(date) =>{
        setLoadingBills(true);
        try{
            const month = date ? moment(date).get('M')+1 : '';
            const year = date ? moment(date).get('year') : '';
            const {data} = await axios.get(`${AppConfig.baseUrl}/billLog/getBilling?month=${month ? month :''}&year=${year ? year : ''}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setBillData(data.billData || []);
            }else{
                throw new Error(data.title)
            }
        }catch(error){
            toast.error(error.message || 'Something went wrong',);
        }
        setLoadingBills(false);
    }

    useEffect(() => {
        setStartDate(new Date(moment().subtract(1, 'month').format('YYYY-MM-DD')))
    },[])

    useEffect(()=>{
        if(startDate){
            getBillingData(startDate)
        }else{
            setBillData([])
        }
    },[startDate])
 
    return (
        <Layout>
            <div className="page-content">
               <Helmet>
                    <title>LeaseJava | Billing Logs</title>
               </Helmet>
                <div className="container-fluid">
                    <Breadcrumbs title="Tables" breadcrumbItem="Billing Logs" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
                                    <MUIDataTable
                                        title={
                                        <Row className="row">
                                            <Col md="4">
                                                <InputGroup className="mb-3">
                                                    <Flatpickr
                                                        onClose={([date]) => {
                                                            if(date){
                                                                setStartDate(date);
                                                            }
                                                            else{
                                                                setStartDate('');
                                                            }
                                                        }}
                                                        value={startDate ? moment(startDate).format('MMMM YYYY') : ''}
                                                        className="form-control d-block bg-white"
                                                        placeholder="Select month and year"
                                                        options={{
                                                            plugins: [
                                                                new monthSelectPlugin({
                                                                    shorthand: true,
                                                                    dateFormat: "F Y",
                                                                    altFormat: "F Y"
                                                                })
                                                            ], 
                                                            disable:[(date) => moment(date).isAfter(moment().subtract(1,'M'))]
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            type="button"
                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                            disabled
                                                        >
                                                            <i
                                                                className="fa fa-calendar"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        }
                                        data={billData}
                                        columns={columns}
                                        options={{ 
                                        rowsPerPage:10, 
                                        print:false, 
                                        download:false, 
                                        filter:false, 
                                        pagination : false,
                                        selectableRows:"none", 
                                        search:false, 
                                        rowsPerPageOptions:[], 
                                        responsive:'scroll',
                                        textLabels: {
                                            body: {
                                              noMatch: loadingBills ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                            },
                                            viewColumns: {
                                            title: "",
                                            },
                                        } 
                                        }}
                                    />
                                    </ThemeProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </Layout>
    )
}

export default BillingLogs
