import PropTypes from "prop-types";
import React from "react";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logos/logo-long.png";
import logoSmall from "../../assets/images/logos/logo-small.png";

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box navbar-header-color vertical-collps" style={{background:'white'}}>
          <Link to="/" className="logo">
            <span className="logo-lg logo-sidebar-name">
              <img src={logo} alt="" height="40" />
            </span>
            <span className="logo-sm">
              <img src={logoSmall} alt="" height="30" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

export default Sidebar
