import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import React, {useContext, useState} from "react";
import axios from "axios";
import { Row, Col, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from 'react-hot-toast';
import AppConfig from "../../constants/config";
import { AuthContext } from "context/userContext";

const FactorAuthenticationPage = () => {
	const { reloadUserData } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: '',
    },
    validationSchema: Yup.object({
      otp: Yup.number().required("Please Enter Valid OTP"),
    }),
    onSubmit: async(values) => {
      setLoading(true);
      try {
        const {data} = await axios.post(`${AppConfig.baseUrl}/super_admin/verify_otp`,values,{
          headers: { token: localStorage.getItem("temp_token") },
        });
        if(!data.error){
          localStorage.setItem('token', data.token);
          reloadUserData()
          if(location && location.state && location.state.key == 'login'){
            toast.success('Login Successful');
            await new Promise(resolve => setTimeout(resolve,1000))
            navigate(`/dashboard`);
          }else{
            navigate("/reset_password");
          }          
        }
        else{
          throw new Error(data.title);
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong');
        navigate('/login')
      }
      setLoading(false);
    }
  });

  const handleResendOtp = async() =>{
    setOtpLoading(true)
    try {
      const {data} = await axios.post(`${AppConfig.baseUrl}/super_admin/resend_otp`,null,{
        headers: { token: localStorage.getItem("temp_token") },
      });
      if(!data.error){
        toast.success('OTP sent Successfully');
      }
      else{
          throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
      navigate('/login')
    }
    setOtpLoading(false)
  }

  return (
    <React.Fragment>
     <Helmet>
        <title>
          LeaseJava | 2 Factor Authentication
        </title>
     </Helmet>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5 login-mt">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className=" bg-softbg-soft-primary bg-theme-color">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-white login-head">Welcome To LeaseJava</h5>
                        <p className="text-white">2 Factor Authentication</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Enter OTP</Label>
                        <Input
                          name="otp"
                          className="form-control"
                          placeholder="Enter OTP"
                          type="number"
                          min="1"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.otp || ""}
                          invalid={
                            validation.touched.otp && validation.errors.otp ? true : false
                          }
                        />
                        {validation.touched.otp && validation.errors.otp ? (
                          <FormFeedback type="invalid">{validation.errors.otp}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end mt-3 d-grid">
                          <button
                            className="btn bg-theme-color w-md "
                            type="submit"
                            disabled={loading || otpLoading}
                          >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            CONFIRM
                          </button>
                        </Col>
                      </Row>
                      <div className="mt-4">
                        <Link to={`#`} onClick={handleResendOtp} className="font-weight-medium txt-theme-color" disabled={loading || otpLoading} >
                          {
                            otpLoading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                          }
                          Resend OTP
                        </Link>
                        <p>
                          Go back to{" "}
                          <Link to="/login" className="font-weight-medium txt-theme-color">
                            Login
                          </Link>{" "}
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

FactorAuthenticationPage.propTypes = {
  history: PropTypes.object,
};

export default FactorAuthenticationPage
