import React, { useEffect, useState } from "react"
import { ButtonDropdown, Dropdown, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap";
import PropTypes from 'prop-types';

const ActionDropdown = (prop) => {
  const {selectedLog, download} = prop;
  const [viewBtn, setViewBtn] = useState(false);
    return (
        <div className="btn-group mb-2 dropstart">
          <ButtonDropdown
            isOpen={viewBtn}
            toggle={() => { setViewBtn(!viewBtn) }}
          >
            <DropdownToggle
              caret
              color="lbsuperadmin"
              className="btn btn-primary btn-sm"
            >
              Download{" "}
              <i className="mdi mdi-chevron-down" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => download(selectedLog.invoice_url, "Invoice")}> Invoice</DropdownItem>
              <DropdownItem onClick={() => download(selectedLog.receipt_url, "Receipt")}> Receipt</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
    )
}

export default ActionDropdown