import React from "react"
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';

const revenueBarChart = (prop) => {
    const { label, datasets, width, height, xAxisDataKey, dataKey } = prop;
    const chartSetting = {
        width: width,
        height: height,
        sx: {
            [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-20px, 0)',
            },
        },
    }; 

    const valueFormatter = (value) => `${value}`;

    return (
    <BarChart
        dataset={datasets}
        xAxis={[{ scaleType: 'band', dataKey: xAxisDataKey }]}
        series={[
        { dataKey: dataKey, label: label, valueFormatter,color:"#005691" }
        ]}        
        {...chartSetting}
    />
  )
}

export default revenueBarChart
