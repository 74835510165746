import PropTypes from 'prop-types';
import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logosmall from "../../assets/images/logos/logo-small.png";
import { AuthContext } from "context/userContext";
import toast from 'react-hot-toast';
import axios from 'axios';
import AppConfig from 'constants/config';

const Header = props => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
	const { userData, reloadUserData } = useContext(AuthContext);
  
  useEffect(()=>{
    setChecked(userData.is_2fa_active);
  }, [userData])

  const factorAuthChange = async() => {
    setLoading(true);
    try {
      const values = {...userData, is_2fa_active: !checked};
      setChecked(!checked);
      const {data} = await axios.post(`${AppConfig.baseUrl}/super_admin/edit_profile`, values, {
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        toast.success('Account updated successfully', { appearance: 'success', autoDismiss:true });
        reloadUserData()
      }
      else{
          throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong', { appearance: 'error', autoDismiss:true });
    }
    setLoading(false);
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logosmall} alt="" height="40" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <h6 style={{ margin: 'auto 8px auto 20px' }}>2FA</h6>
            <div className="form-check form-switch form-switch-lg" style={{ alignContent: 'center', marginTop: '18px' }}>
              <input
                type="checkbox"
                className="form-check-input"
                id="2fa"
                checked={checked}
                disabled={loading}
                onChange={factorAuthChange}
              />
              {
                  loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
              }
              </div>
          </div>
          <div className="d-flex">
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

export default Header
