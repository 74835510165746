import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = (prop) => {
    const { labels, data, height ,fullLabels} = prop;

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "45%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        colors: ["#005691"],
        xaxis: {
            categories: labels,
        },
        fill: {
            opacity: 1
        },
        tooltip:{
            enabled: true,
            x:{
                show: true,
                formatter: function(val, opts) {
                    if(fullLabels && fullLabels.length > 0 && opts && opts.dataPointIndex >=0){
                        return fullLabels[opts.dataPointIndex]
                    }
                    else{
                        return val ? val : ''
                    }
                }
            }
        }
    }

    const series = [
        {
            name: "Revenue",
            data: data,
        },
    ];
    
    return (
      <ReactApexChart options={options} series={series} type="bar" height={height ? height : 320} />
    );
}

export default LineChart;
