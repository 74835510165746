import React, { useState, useEffect, useContext } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import { Link } from "react-router-dom"
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { AuthContext } from "context/userContext";

const ProfileMenu = props => {
	const { userData } = useContext(AuthContext);
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Admin")

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setusername(userData.name);
    }
  }, [userData])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            Profile{" "}
          </Link>
          <div className="dropdown-divider"/>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
}

export default ProfileMenu