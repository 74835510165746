import React, { useState,useEffect } from "react"
import { Row, Col,Input } from "reactstrap";
import moment from 'moment';
import {commaSeperatedNumber} from "helpers/stringHelper"
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const RowAsset = (prop) => {
  const [InitialData, setInitialData] = useState([]);
  const [search, setSearch] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const columns = [
    {
      label: 'Date',
      name: 'date',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        customBodyRender: (data) => {
          return moment.utc(data).format('MMMM, YYYY')
        }
      }
    },
    {
      label: 'Modification Adj',
      name: 'modification_adjustment',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      label: 'Impairment',
      name: 'impairment',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      label: 'Beg',
      name: 'beginning',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      label: `${prop.mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`,
      name: 'amort',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      label: 'Ending',
      name: 'ending',
      options: {
        filter: false,
        sort: true,
      }
    },
  ]

  useEffect(()=>{
    if(prop.data) {
      let shortTermData = prop.leaseDetails && prop.leaseDetails.modifiedLease && prop.leaseDetails.modifiedLease.length > 0 ? prop.leaseDetails.modifiedLease.find(v=>v.is_short_term == true) : ''
      let longTermData = prop.leaseDetails && prop.leaseDetails.modifiedLease && prop.leaseDetails.modifiedLease.length > 0 ? prop.leaseDetails.modifiedLease.find(v=>v.is_short_term != true) : ''
      let longTermDate = longTermData ? moment(longTermData.modification_date ? longTermData.modification_date : longTermData.commencement).utc().format('YYYY-MM-DD') : !prop.leaseDetails.is_short_term ? moment(prop.leaseDetails.modification_date ? prop.leaseDetails.modification_date : prop.leaseDetails.commencement).utc().format('YYYY-MM-DD') : moment(prop.leaseDetails.terminal_end_date).add(1,'day').format('YYYY-MM-DD')
      const mainData = []
      for(const d of prop.data){
        let obj = {
          beginning: d.beginning && Number(Number(d.beginning).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.beginning) : 0,
          date: d.date,
          ending: d.ending && Number(Number(d.ending).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.ending) : 0,
          amort: d.amort && Number(Number(d.amort).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.amort) : 0,
          modification_adjustment: d.modification_adjustment && Number(Number(d.modification_adjustment).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.modification_adjustment) : 0,
          impairment: d.impairment && Number(Number(d.impairment).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.impairment) : 0,
          _id: d._id
        }
        mainData.push(obj)
      }
      const rouData = mainData.filter(v => moment(moment(v.date).utc().format('YYYY-MM-DD')).isSameOrAfter(moment(longTermDate,'YYYY-MM-DD')))
      const data = mainData.filter(v =>moment(moment(v.date).utc().format('YYYY-MM-DD')).isSame(moment(moment(longTermDate).subtract(1,'M').format('YYYY-MM-DD'))))
      const lastEnding = data && data[0] && data[0].ending
      if(prop.isModified && (shortTermData && shortTermData.is_short_term) && !prop.leaseDetails.is_short_term){
        let obj = {
          amort: 0,
          beginning: 0,
          date: moment.utc(new Date(moment(longTermDate).subtract(1,'M').format('YYYY-MM-DD'))).format('MMMM, YYYY'),
          ending: lastEnding && Number(Number(lastEnding).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(lastEnding) : 0,
          modification_adjustment: 0,
          impairment: 0,
          _id: moment(longTermDate).subtract(1,'M').format('YYYY-MM')
        }
        rouData.splice(0,0,obj)
      }
      setInitialData(rouData)
      setOriginalData(rouData)
    }
  },[prop.data,prop.leaseDetails])

  const handleSearch = (value) => {
    const data = originalData.filter(v => v.date.match(new RegExp(value,"i")) || v.modification_adjustment.toString().match(new RegExp(value,"i")) || v.impairment.toString().match(new RegExp(value,"i")) || v.beginning.toString().match(new RegExp(value,"i")) || v.amort.toString().match(new RegExp(value,"i")) || v.ending.toString().match(new RegExp(value,"i")))
    setInitialData(data)
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
            MUIDataTableToolbar:{
              styleOverrides:{
                actions: {
                  display: 'contents'
                }
              }
            }
          }})}>
        <MUIDataTable
          title={
            <Row className="row">
              <Col className="search-p">
                <div className="ms-2 me-2 mb-3 mt-3">
                  <div className="position-relative sw">
                  <Input
                      placeholder="Search"
                      type="text"
                      value={search}
                      onChange={(e) => {handleSearch(e.target.value);setSearch(e.target.value)}}
                  />
                  </div>
                </div>
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
          }
          data={InitialData}
          columns={columns}
          options={{ 
            rowsPerPage:50000, //InitialData.length/10
            print:false, 
            download:true, 
            filter:false, 
            selectableRows:"none", 
            search:false, 
            rowsPerPageOptions:[], 
            responsive:'scroll',
            textLabels: {
              body: {
                noMatch: ""
              },
              viewColumns: {
                title: "",
              },
            },
            downloadOptions:{
              filename:'rouAsset.csv'
            },
            rowHover: false,
            setRowProps: (row, dataIndex, rowIndex) => {
              return {
                style: { backgroundColor: rowIndex % 2 === 0 ? '#ffffff' : '#eeeeee'},
              };
            },
          }}
        />
      </ThemeProvider>
    </React.Fragment>
  )
}

export default RowAsset;
