import React, { useState, useEffect } from "react"
import { Row, Col, Input, Spinner } from "reactstrap";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import {commaSeperatedNumber} from "helpers/stringHelper"
import moment from 'moment';

const GlMappingJE = (prop) => {
    const [InitialData, setInitialData] = useState([]);
    const [search, setSearch] = useState("");
    const [originalData, setOriginalData] = useState([]); 
    const [loading, setLoading] = useState(true); 
    
    const columns = [
      {
        label: 'Date',
        name: 'date',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        }
      },
      {
        label: 'Description',
        name: 'description',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '250px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
        }
      },
      {
        label: 'Account Number',
        name: 'account_no',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
        }
      },
      {
        label: 'Account Description',
        name: 'account_description',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '250px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
        }
      },
      {
        label: 'Allocation Type',
        name: 'allocation_type',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
        }
      },
      {
        label: 'Allocation Number',
        name: 'allocation_no',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
        }
      },
      {
        label: 'Allocation Description',
        name: 'allocation_description',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '200px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
        }
      },
      {
        label: 'Financial statement line item',
        name: 'fsli',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '250px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
        }
      },
      {
        label: 'Amount',
        name: 'amount',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        }
      },
      {
        label: 'Amount (Absolute value)',
        name: 'amount_abs',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '200px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
        }
      },
      {
        label: 'Debit/Credit',
        name: 'debit_credit',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        }
      },
      {
        label: 'Debit',
        name: 'debit',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        }
      },
      {
        label: 'Credit',
        name: 'credit',
        options: {
          filter: false,
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        }
      }
    ]

    useEffect(() => {
      if (prop.data) {
        setLoading(true)
        let data = prop.data && prop.data.glmappedData && prop.data.glmappedData.length > 0 ? prop.data.glmappedData : []
        let mapData = data && data.filter(d => Number(Number(d.amount).toFixed(2)) != 0)
        const mainData = []
        for(const d of mapData){
          let obj = {
            account_description: d.account_description ? d.account_description : '',
            account_no: d.account_no ? d.account_no : '',
            allocation_description: d.allocation_description ? d.allocation_description : '',
            allocation_no: d.allocation_no ? d.allocation_no : '',
            allocation_type: d.allocation_type ? d.allocation_type : '',
            amount: d.amount && Number(Number(d.amount).toFixed(2)) != 0 ? commaSeperatedNumber(Number(d.amount)) : '',
            date: moment.utc(d.date).format('MM/DD/YYYY'),
            description: d.description ? d.description : '',
            fsli: d.fsli ? d.fsli : '',
            debit: Number(Number(d.amount).toFixed(2)) > 0 ? commaSeperatedNumber(Number(d.amount)) : '',
            credit:Number(Number(d.amount).toFixed(2)) < 0 ? commaSeperatedNumber(Number(Math.abs(d.amount))) : '',
            amount_abs: d.amount && Number(Number(d.amount).toFixed(2)) != 0 ? commaSeperatedNumber(Number(Math.abs(d.amount))) : '',
            debit_credit: Number(Number(d.amount).toFixed(2)) > 0 ? 'Debit' : Number(Number(d.amount).toFixed(2)) < 0 ? 'Credit' : ''
          }
          mainData.push(obj)
        }
        setInitialData(mainData || [])
        setOriginalData(mainData || [])
        setLoading(false)
      }
    }, [prop.data])

    const handleSearch = (value) => {
    const data = originalData.filter(v => v.date.match(new RegExp(value,"i")) || v.description.match(new RegExp(value,"i")) || v.account_no.match(new RegExp(value,"i")) || v.account_description.match(new RegExp(value,"i")) || v.allocation_type.match(new RegExp(value,"i")) || v.allocation_no.match(new RegExp(value,"i")) || v.allocation_description.match(new RegExp(value,"i")) || v.fsli.match(new RegExp(value,"i")) || v.amount.match(new RegExp(value,"i")))
    setInitialData(data)
    }

  return (
    <React.Fragment>   
        <ThemeProvider
        theme={createTheme({
            components: {
            ...getMuiTheme(),
            MUIDataTableToolbar: {
                styleOverrides: {
                actions: {
                    display: "contents",
                },
                },
            },
            },
        })}
        >
        <MUIDataTable
            title={
            <Row className="row">
                <Col className="search-p">
                <div className="me-2 mb-3">
                    <div className="position-relative sw">
                    <Input
                        placeholder="Search"
                        type="text"
                        value={search}
                        onChange={(e) => {
                        handleSearch(e.target.value);
                        setSearch(e.target.value);
                        }}
                    />
                    </div>
                </div>
                </Col>
                <Col md="3"></Col>
                <Col md="6" style={{ paddingRight: "unset" }}></Col>
            </Row>
            }
            data={InitialData}
            columns={columns}
            options={{
            rowsPerPage: 50000, //InitialData.length/50000
            print: false,
            download: true,
            filter: false,
            selectableRows: "none",
            search: false,
            rowsPerPageOptions: [],
            tableBodyHeight: "370px",
              textLabels: {
                body: {
                  noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found" },
                viewColumns: {
                  title: "",
                },
              },
            downloadOptions: {
                filename: "glmapping.csv",
            },
            rowHover: false,
            setRowProps: (row, dataIndex, rowIndex) => {
                return {
                style: {
                    backgroundColor:
                    rowIndex % 2 === 0 ? "#ffffff" : "#eeeeee",
                },
                };
            },
            }}
        />
        </ThemeProvider>
    </React.Fragment>
  )
}

export default GlMappingJE;
