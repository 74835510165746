export const getMuiTheme = () => {
    return {
      MUIDataTableHeadCell: {
        styleOverrides:{
          root: {
            backgroundColor: "#eff2f7",
            fontWeight: "600 !important" ,
            fontSize: '.8125rem !important'
          }
        }
      },
      MuiButton: {
        styleOverrides:{
          root: {
            padding: "0",
            textTransform: 'none',
            fontWeight: "600 !important" ,
            fontSize: '.8125rem !important'
          }
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides:{
          root: {
            padding: "0",
            fontSize: '.8125rem !important'
          }
        }
      },
      MuiTableCell: {
        styleOverrides:{
          root: {
            padding: "8px",
            fontFamily: '"Poppins", sans-serif',
            lineHeight: 1.5,
            color: '#495057'
          }
        }
      },
      MuiTablePagination: {
        styleOverrides:{
          displayedRows: {
            margin: "0 !important",
          }
        }
      },
      MuiFormControl: {
        styleOverrides:{
          root: {
            paddingLeft: "10px",
          }
        }
      },
      MUIDataTableViewCol: {
        styleOverrides:{
          checkbox: {
            width: "37px",
            height: "0px"
          },
          label: {
            width: "100px",
          }
        }
      },
      MuiTypography: {
        styleOverrides:{
          root: {
            fontSize: '.8125rem !important'
          }
        }
      },
      MUIDataTableToolbar: {
        styleOverrides:{
          filterCloseIcon: {
            visibility: 'hidden'
          }
        }
      },
      MuiPopover: {
        styleOverrides:{
          paper: {
            right: '70px',
            width: '170px',
            left: 'auto !important'
          }
        }
      },
      MUIDataTable: {
        styleOverrides:{
          paper: {
            boxShadow: 'none !important',
          }
        }
      },
      MuiToolbar: {
        styleOverrides:{
          root: {
            padding: '0 !important',
          }
        }
      },
      MUIDataTableBody:{
        styleOverrides:{
          emptyTitle: {
            minHeight: '32px',
            marginTop: '12px !important'
          }
        }
      }
    }    
  }