import React,{useState} from "react"
import {Modal} from "reactstrap"
import "../../assets/scss/manual/manual.scss";
import axios from "axios";
import AppConfig from "constants/config";
import toast from 'react-hot-toast';

const DeleteUser = (prop) => {
    const {deleteOpen, toggleDelete, setDeleteOpen, user, getUserData,  setSelectedUser} = prop;
    const [loading, setLoading] = useState(false);

    const handleDeleteUser = async(e) => {
        setLoading(true)
        try {
            e.preventDefault()
            const {data} = await axios.post(`${AppConfig.baseUrl}/users/delete_user`,{user_id: user._id}, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                toast.success('User deleted successfully');
                setDeleteOpen(false);
                getUserData();
                setSelectedUser({});
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            setDeleteOpen(false);
            setSelectedUser({});
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }

    return (
        <Modal
            isOpen={deleteOpen}
            toggle={() => {
                toggleDelete();
                setSelectedUser({});
            }}
            centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">Delete User</h5>
                <button
                    type="button"
                    onClick={() => {
                        setDeleteOpen(false);
                        setSelectedUser({});
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <form onSubmit={handleDeleteUser}>
                    <div className="mb-3">
                        Are you sure you want to delete this user?
                    </div>
                    <div className="modal-footer mt-3">
                        <button
                            type="button"
                            className="btn bg-theme-color text-white"
                            onClick={() =>{
                                setDeleteOpen(false);
                                setSelectedUser({});
                            }} 
                            disabled={loading} 
                        >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            No
                        </button>
                        <button type="submit" className="btn bg-theme-color text-white" disabled={loading} >
                            {
                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                            }
                            Yes
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default DeleteUser