const capitalize = (text) => {
    return String(text)
        .replace("_", " ")
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
};

function commaSeperatedNumber(n) {
    const a = String(n).replace(',', '');
    return Number(a).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

function commaSeperatedNumberWithoutDecimal(n) {
    const a = String(n).replace(',', '');
    return Number(a).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
}

export {
    capitalize,
    commaSeperatedNumber,
    commaSeperatedNumberWithoutDecimal,
}