import React from "react"
import {Table} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import moment from 'moment';
import { capitalize } from "lodash";
import {commaSeperatedNumber} from "helpers/stringHelper"

const LeaseSummary = (prop) => {
    const {leaseDetails, leaseDraft, refundableAmount, currency, paymentsData} = prop

    const totalAmort = (paymentsData ? Number(paymentsData.total_lease_payment) : 0) - (paymentsData ? Number(paymentsData.total_present_value) : 0) + (leaseDetails && leaseDetails.rouAsset && leaseDetails.rouAsset[0] ? leaseDetails.rouAsset[0].beginning :0)
    const totalTransitionAmt = leaseDraft ? 
    ((leaseDraft.acquisitionRelated ? Number(leaseDraft.acquisitionRelated) : 0 )
    + (leaseDraft.prepaidRent ? Number(leaseDraft.prepaidRent) : 0)
    + (leaseDraft.unamortizedInitialDirectCost ? Number(leaseDraft.unamortizedInitialDirectCost) : 0)
    + (leaseDraft.leaseIncentives ? Number(leaseDraft.leaseIncentives) : 0 )
    + (leaseDraft.deferredRent ? Number(leaseDraft.deferredRent) : 0)
    + (leaseDraft.topic420Liability ? Number(leaseDraft.topic420Liability) : 0)
    + (leaseDraft.otherAccounts ? Number(leaseDraft.otherAccounts) : 0)) 
    : 0;
    
return(
    <React.Fragment>
        <div className="table-responsive">
            <Table className="table-nowrap">
                <tbody>
                    <tr style={{backgroundColor: leaseDetails && leaseDetails.status == 'Modified' ? '#eeeeee' : '#fff'}}>
                        <td><h6 className="mt-2">GAAP - Lease Commencement Date:</h6></td>
                        <td className="text-end">{leaseDetails && leaseDetails.commencement ? moment.utc(leaseDetails.commencement).format('MM/DD/YYYY') : ''}</td>
                    </tr>
                    {
                        leaseDetails && leaseDetails.status == 'Modified' &&
                        <tr>
                            <td><h6 className="mt-2">Modification Date:</h6></td>
                            <td className="text-end">{leaseDetails && leaseDetails.modification_date ? moment.utc(leaseDetails.modification_date).format('MM/DD/YYYY') : ''}</td>
                        </tr>
                    }
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td><h6 className="mt-2">Transition Date:</h6></td>
                        <td className="text-end">{leaseDraft && leaseDraft.transitionDate ? moment.utc(leaseDraft.transitionDate).format('MM/DD/YYYY') : ''}</td>
                    </tr>
                    <tr>
                        <td><h6 className="mt-2">GAAP - Lease End Date:</h6></td>
                        <td className="text-end">{leaseDetails && leaseDetails.terminal_end_date ? moment.utc(leaseDetails.terminal_end_date).format('MM/DD/YYYY') : ''}</td>
                    </tr>
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td><h6 className="mt-2">GAAP - Lease Classification:</h6></td>
                        <td className="text-end"><span style={{textTransform: 'capitalize'}}>{leaseDetails && leaseDetails?.classification}</span></td>
                    </tr>

                    <tr>
                        <td><h6 className="mt-2">Useful Life of Underlying Asset:</h6></td>
                        <td className="text-end">{leaseDetails && leaseDetails.useful_eco_life ? commaSeperatedNumber(leaseDetails.useful_eco_life) : ''}</td>
                    </tr>
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td><h6 className="mt-2">Discount Rate:</h6></td>
                        <td className="text-end">{leaseDetails && leaseDetails.discount_rate ? leaseDetails.discount_rate + " %" : ''} ({leaseDetails && leaseDetails.interest_rate_method && leaseDetails.interest_rate_method == 'monthly_compound' ?  'Monthly compounded rate' : 'True annual rate' })</td>
                    </tr>
                    <tr>
                        <td><h6 className="mt-2">Lease Type:</h6></td>
                        <td className="text-end">{leaseDetails && leaseDetails.calculation_type ? capitalize(leaseDetails.calculation_type) : 'Daily'}</td>
                    </tr>
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td><h6 className="mt-2">Deposit Amount:</h6></td>
                        <td className="text-end">{currency+" "}{Number(refundableAmount) > 0 ? commaSeperatedNumber(refundableAmount) : 0}</td>
                    </tr>
                    <tr>
                        <td><h6 className="mt-2">Transition Amount:</h6></td>
                        <td className="text-end">{currency+" "}{totalTransitionAmt && commaSeperatedNumber(totalTransitionAmt)}</td>
                    </tr>
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td><h6 className="mt-2">Undiscounted {leaseDetails.mode == 'lessor' ? "Receivable"  : "Lease Liability"} @ Lease Commencement:</h6></td>
                        <td className="text-end">{currency+" "}{paymentsData && paymentsData.total_lease_payment && commaSeperatedNumber(Number(paymentsData.total_lease_payment))}</td>
                    </tr>
                    <tr>
                        <td><h6 className="mt-2">PV of {leaseDetails.mode == 'lessor' ? "Receivable"  : "Lease Liability"} at Lease Commencement:</h6></td>
                        <td className="text-end">{currency+" "}{paymentsData && paymentsData.total_present_value && commaSeperatedNumber(Number(paymentsData.total_present_value))}</td>
                    </tr>
                    <tr style={{backgroundColor:'#eeeeee'}}>
                        <td><h6 className="mt-2">{ leaseDetails.mode == 'lessor' ? "Deferred Inflow Of Resources" : "ROU ASSET"} at Lease commencement date:</h6></td>
                        <td className="text-end">{currency+" "}{leaseDetails && leaseDetails.rouAsset && leaseDetails.rouAsset[0] && leaseDetails.rouAsset[0].beginning && commaSeperatedNumber(leaseDetails.rouAsset[0].beginning)}</td>
                    </tr>
                    <tr>
                        <td><h6 className="mt-2">Total Interest and Total Interest and { leaseDetails.mode == 'lessor' ? "Lease Revenue" : "Amortization"}:</h6></td>
                        <td className="text-end">{currency+" "}{totalAmort && commaSeperatedNumber(totalAmort)}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    </React.Fragment>
)
}

export default LeaseSummary