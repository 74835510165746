import React, { useEffect, useState } from "react"
import {Helmet} from "react-helmet"
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
    Spinner,
    InputGroup
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useLocation, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import { object, string } from "yup";
import { useFormik } from "formik";
import moment from "moment";
import Layout from "components/VerticalLayout";

const EditAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isChecked, setIsChecked] = useState(false)
    const [id, setId] = useState('');
    const [accountData, setAccountData] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);

    const validationSchema = object().shape({
        company: string().required("* Company name is required"),
        contact_person: string().required("* Contact name is required"),
        street_address: string().required("* Company address is required"),
        city: string().required("* City is required"),
        state: string().required("* State is required"),
        email: string().email("Please enter valid email").required("* Email is required"),
        phone_number: string().length(10, "Please enter valid phone number").required("* Phone number is required"),
        primary_contact: string().length(10, "Please enter valid phone number").required("* Primary contact is required"),
        admin_name: string().required("* Admin name is required"),
        admin_email: string().email("Please enter valid email").required("* Admin email is required"),
        discount: string().required("* Discount is required"),
    });
  
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
			company: "",
			contact_person: "",
			street_address: "",
            street_address_2: "",
            city: "",
            state: "",
            email: "",
            phone_number: "",
            primary_contact: "",
            admin_name: "",
            title: "",
            admin_email: "",
            start_date: moment().format('MM/DD/YYYY'),
            discount: "DISC0",
            is_online: false,
        },
        validationSchema: validationSchema,
        onSubmit: async(values) => {
            setLoading(true);
            try {
                values.id = accountData.customer_id._id;
                const {data} = await axios.post(`${AppConfig.baseUrl}/customer/editCustomer`, values, {
                    headers: { token: localStorage.getItem("token") }
                });
                if(!data.error){
                    formik.resetForm();
                    toast.success('Account updated successfully');
                    navigate("/accounts")
                }
                else{
                    throw new Error(data.title);
                }
            } catch (error) {
                toast.error(error.message || 'Something went wrong');
            }
            setLoading(false)
        }
    });

    useEffect(() => {
        if(id){
            getAccountData();
        }
        // eslint-disable-next-line
    },[id])

    useEffect(() => {
        if(accountData){
            formik.setValues({
                company: accountData.customer_id.company || '',
                contact_person: accountData.contact_person || '',
                street_address: accountData.street_address || '',
                street_address_2: accountData.street_address_2 || '',
                city: accountData.city || '',
                state: accountData.state || '',
                email: accountData.email || '',
                phone_number: accountData.phone_number || '',
                primary_contact: accountData.primary_contact || '',
                admin_name: accountData.admin_name || '',
                admin_email: accountData.admin_email || '',
                title: accountData.title || '',
                discount: accountData.customer_id.discount || 'DISC0',
                start_date:accountData.customer_id.start_date || '',
                is_online: accountData.customer_id.is_online || false,
            })
            const checked = accountData.contact_person && accountData.email && accountData.admin_name && accountData.admin_email 
                && accountData.contact_person === accountData.admin_name && accountData.email === accountData.admin_email
            setIsChecked(checked)
        }
    },[accountData])

    useEffect(() => {
        if(isChecked){
            formik.setFieldValue("admin_name", formik.values.contact_person)
            formik.setFieldValue("admin_email", formik.values.email)
            formik.setFieldTouched("admin_name", false)
            formik.setFieldTouched("admin_email", false)
        }
        else{
            formik.setFieldValue("admin_name", "")
            formik.setFieldValue("admin_email", "")
        }
    // eslint-disable-next-line
    }, [isChecked])

    useEffect(() => {
        setId((location.search).replace('?', ''));
    }, [])


    const getAccountData = async () => {
        setLoadingData(true);
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getDetails?id=${id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setAccountData(data.custData)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            navigate("/accounts")
        }
        setLoadingData(false);
    }

    return (
        <Layout>
            <div className="page-content">
               <Helmet>
                    <title>LeaseJava | Edit Account</title>
               </Helmet>
                <Container fluid={true}>
                    <Breadcrumbs title="Forms" breadcrumbItem="Edit Account" />
                    {
                        loadingData ?
                            <div style={{textAlign: 'center'}}><Spinner color="primary" className="txt-theme-color" /></div>
                        :
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <form onSubmit={formik.handleSubmit}>
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="contact_person">
                                                            Contact name
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="contact_person"
                                                            onChange={(e) => {
                                                                if(isChecked){
                                                                    formik.setFieldValue("admin_name", e.target.value)
                                                                }
                                                                formik.handleChange(e);
                                                            }}
                                                            onBlur={() => formik.setFieldTouched("contact_person")}
                                                            value={formik.values.contact_person}
                                                        />
                                                        {formik.touched.contact_person && formik.errors.contact_person && (
                                                            <div className="text-danger">{formik.errors.contact_person}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="company">
                                                            Company name
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="company"
                                                            onChange={formik.handleChange}
                                                            onBlur={() => formik.setFieldTouched("company")}
                                                            value={formik.values.company}
                                                        />
                                                        {formik.touched.company && formik.errors.company && (
                                                            <div className="text-danger">{formik.errors.company}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="street_address">
                                                            Company street address
                                                        </Label>
                                                        <Input
                                                            type="textarea"
                                                            rows={1}
                                                            className="form-control"
                                                            id="street_address"
                                                            onChange={formik.handleChange}
                                                            onBlur={() => formik.setFieldTouched("street_address")}
                                                            value={formik.values.street_address}
                                                        />
                                                        {formik.touched.street_address && formik.errors.street_address && (
                                                            <div className="text-danger">{formik.errors.street_address}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="street_address_2">
                                                            Company street address 2 (Optional)
                                                        </Label>
                                                        <Input
                                                            type="textarea"
                                                            rows={1}
                                                            className="form-control"
                                                            id="street_address_2"
                                                            onChange={formik.handleChange}
                                                            onBlur={() => formik.setFieldTouched("street_address_2")}
                                                            value={formik.values.street_address_2}
                                                        />
                                                        {formik.touched.street_address_2 && formik.errors.street_address_2 && (
                                                            <div className="text-danger">{formik.errors.street_address_2}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="city">
                                                            City
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="city"
                                                            onChange={formik.handleChange}
                                                            onBlur={() => formik.setFieldTouched("city")}
                                                            value={formik.values.city}
                                                        />
                                                        {formik.touched.city && formik.errors.city && (
                                                            <div className="text-danger">{formik.errors.city}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="state">
                                                            State
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="state"
                                                            onChange={formik.handleChange}
                                                            onBlur={() => formik.setFieldTouched("state")}
                                                            value={formik.values.state}
                                                        />
                                                        {formik.touched.state && formik.errors.state && (
                                                            <div className="text-danger">{formik.errors.state}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="email">
                                                            Email
                                                        </Label>
                                                        <Input
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            onChange={(e) => {
                                                                if(isChecked){
                                                                    formik.setFieldValue("admin_email", e.target.value)
                                                                }
                                                                formik.handleChange(e);
                                                            }}
                                                            onBlur={() => formik.setFieldTouched("email")}
                                                            value={formik.values.email}
                                                        />
                                                        {formik.touched.email && formik.errors.email && (
                                                            <div className="text-danger">{formik.errors.email}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="phone_number">
                                                            Phone
                                                        </Label>
                                                        <Input
                                                            type="number"
                                                            className="form-control"
                                                            id="phone_number"
                                                            onChange={formik.handleChange}
                                                            onBlur={() => formik.setFieldTouched("phone_number")}
                                                            value={formik.values.phone_number}
                                                        />
                                                        {formik.touched.phone_number && formik.errors.phone_number && (
                                                            <div className="text-danger">{formik.errors.phone_number}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="primary_contact">
                                                            Primary contact
                                                        </Label>
                                                        <input
                                                            type="number"
                                                            id="primary_contact"
                                                            className="form-control"
                                                            onChange={formik.handleChange}
                                                            onBlur={() => formik.setFieldTouched("primary_contact")}
                                                            value={formik.values.primary_contact}
                                                        />
                                                        {formik.touched.primary_contact && formik.errors.primary_contact && (
                                                            <div className="text-danger">{formik.errors.primary_contact}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="discount">
                                                            Discount Code
                                                        </Label>
                                                        <select
                                                            value={formik.values.discount}
                                                            onChange={formik.handleChange}
                                                            id="formrow-InputState"
                                                            className="form-control"
                                                            name="discount"
                                                        >
                                                        <option name="DISC0" value="DISC0">No discount</option>
                                                        <option name="WEBD10" value="WEBD10">WEBD10</option>
                                                        <option name="FFAD15" value="FFAD15">FFAD15</option>
                                                        <option name="RCD20" value="RCD20">RCD20</option>
                                                        <option name="FFD30" value="FFD30">FFD30</option>
                                                        </select>
                                
                                                        {formik.touched.discount && formik.errors.discount && (
                                                            <div className="text-danger">{formik.errors.discount}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="is_online">
                                                            Online payment
                                                        </Label>
                                                        <InputGroup>
                                                            <div className="form-check form-switch form-switch-lg" style={{ alignContent: 'center'}}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="is_online"
                                                                    checked={formik.values.is_online}
                                                                    disabled={loading}
                                                                    onChange={formik.handleChange}
                                                                />
                                                                {
                                                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                                }
                                                            </div>
                                                        </InputGroup>
                                                    </div>
                                                </Col>
                                                {
                                                   accountData&& accountData.customer_id&&accountData.customer_id.status && accountData.customer_id.status=='trial' && <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="start_date">
                                                            Start date
                                                        </Label>
                                                        <InputGroup>
                                                            <Flatpickr
                                                                onClose={([date]) => {
                                                                    formik.setFieldValue("start_date", date ? moment(date).format('MM/DD/YYYY') : "")
                                                                }}
                                                                name="start_date"
                                                                onBlur={() => formik.setFieldTouched("start_date")}
                                                                value={formik.values.start_date}
                                                                className="form-control d-block bg-white"
                                                                options={{
                                                                    dateFormat: "m/d/Y",
                                                                    allowInput: true,
                                                                    minDate: moment().format('MM/DD/YYYY'),
                                                                    maxDate: moment().add(1, 'month').format('MM/DD/YYYY')
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                    disabled
                                                                >
                                                                    <i
                                                                        className="fa fa-calendar"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                            </div>
                                                            {formik.touched.start_date && formik.errors.start_date && (
                                                                <div className="text-danger">{formik.errors.start_date}</div>
                                                            )}
                                                        </InputGroup>
                                                    </div>
                                                </Col>
                                                }                                                
                                            </Row>
                                            <Row>
                                                <Col md="12" lg="12" xl="12" xxl="12">
                                                    <div className="form-check mb-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                setIsChecked(!isChecked)
                                                            }} 
                                                            checked={isChecked}
                                                            id="details_check"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="details_check"
                                                        >
                                                            Admin details are same as company contact details
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6" lg="6" xl="6" xxl="6">
                                                    <div className="mb-3">
                                                        <Label for="admin_name">
                                                            Admin Name
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="admin_name"
                                                            onChange={formik.handleChange}
                                                            onBlur={() => formik.setFieldTouched("admin_name")}
                                                            value={formik.values.admin_name}
                                                        />
                                                        {formik.touched.admin_name && formik.errors.admin_name && (
                                                            <div className="text-danger">{formik.errors.admin_name}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col md="6" lg="6" xl="6" xxl="6">
                                                    <div className="mb-3">
                                                        <Label for="title">
                                                            Title
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="title"
                                                            onChange={formik.handleChange}
                                                            onBlur={() => formik.setFieldTouched("title")}
                                                            value={formik.values.title}
                                                        />
                                                        {formik.touched.title && formik.errors.title && (
                                                            <div className="text-danger">{formik.errors.title}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6" lg="6" xl="6" xxl="6">
                                                    <div className="mb-3">
                                                        <Label for="admin_email">
                                                            Email
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="admin_email"
                                                            onChange={formik.handleChange}
                                                            onBlur={() => formik.setFieldTouched("admin_email")}
                                                            value={formik.values.admin_email}
                                                        />
                                                        {formik.touched.admin_email && formik.errors.admin_email && (
                                                            <div className="text-danger">{formik.errors.admin_email}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <button type="submit" className="btn bg-theme-color w-md" disabled={loading || accountData && accountData.customer_id && accountData.customer_id.is_deleted === true}>
                                                {
                                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                }
                                                SUBMIT
                                            </button>
                                                                    
                                        </form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </Layout>
    )
}

export default EditAccount
