import React from "react"
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import { Spinner} from "reactstrap"


const AccountsTable = (prop) => {
    const {columns, accountsData, loading} = prop

    return (
        <React.Fragment>
            <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
            <MUIDataTable
                title="ACCOUNTS"
                data={accountsData}
                columns={columns}
                options={{ 
                rowsPerPage:10, 
                print:false, 
                download:false, 
                filter:false, 
                selectableRows:"none", 
                search:false, 
                rowsPerPageOptions:[], 
                responsive:'scroll',
                textLabels: {
                    body: {
                      noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                    },
                    viewColumns: {
                    title: "",
                    },
                } 
                }}
            />
            </ThemeProvider>
        </React.Fragment>
    )
}

export default AccountsTable
