import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import AppConfig from "constants/config";

const initialState = {
	userData: {
        id: "",
		name: "",
		email: "",
        is_2fa_active: false,
	},
	onLogout: async ()=> {},
	reloadUserData: async (credentials) => {},
	loading: false
};

const AuthContext = React.createContext(initialState);

const AuthProvider = ({ children }) => {
	const [userData, setUserData] = useState({
        id: "",
		name: "",
		email: "",
        is_2fa_active: false,
	});
	const [loading, setLoading] = useState(true);

	const onLogout = async () => {
		setUserData(initialState.userData);
		localStorage.clear();
		sessionStorage.clear();
		window.location.href = "/login"
	};

	const reloadUserData = async () => {
		try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/super_admin/token/refresh`, null, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                localStorage.setItem("token", data.token);
                const payload = data.tokenData
                setUserData({
                    id: payload.user_id,
                    name: payload.name,
                    email: payload.email,
                    is_2fa_active: payload.is_2fa_active,
                });
            }
            else{
                throw new Error(data.title);
            }
		} catch(e) {
            onLogout();
        }

		setLoading(false);
	};

	useEffect(() => {
		const verifyToken = async () => {
			const token = localStorage.getItem("token")
			setLoading(true);
			try {
				const { data } = await axios.post(`${AppConfig.baseUrl}/super_admin/token`, null, {
					headers: { token: localStorage.getItem("token") }
				});
                if(!data.error){
                    const payload = data.tokenData
                    setUserData({
                        id: payload.user_id,
                        name: payload.name,
                        email: payload.email,
                        is_2fa_active: payload.is_2fa_active,
                    });
                }
                else{
                    throw new Error(data.title);
                }
			} catch (e) {
                onLogout()
			}
			setLoading(false);
			localStorage.setItem('token', token)
		};

		if (window.location.pathname !== "/login" && window.location.pathname !== '/factorauth') {
			verifyToken();
		}
	}, [window.location.pathname]);
	
	const value = {
		userData,
		onLogout,
		reloadUserData,
		loading
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthConsumer = AuthContext.Consumer;

AuthProvider.propTypes = {
    children: PropTypes.any,
};

export { AuthProvider, AuthConsumer, AuthContext };
