import React, { useState,useEffect} from "react"
import {Row, Col, Input} from "reactstrap";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import moment from 'moment';
import {commaSeperatedNumber} from "helpers/stringHelper"

const STBalance = (prop) => {
  const [InitialData, setInitialData] = useState([]);
  const [search, setSearch] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const columns = [
    {
      label: 'Date',
      name: 'date',
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
      }
    },
    {
      label: 'Beg',
      name: 'beginning',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      label: 'Expense',
      name: 'expense',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      label: 'Payment',
      name: 'payment',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      label: 'Transfers',
      name: 'transfer',
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      label: 'Ending',
      name: 'ending',
      options: {
        filter: false,
        sort: true,
      }
    },
  ]

  useEffect(()=>{
    if(prop.data) {
        let longTermData = prop.data && prop.data.modifiedLease && prop.data.modifiedLease.length > 0 ? prop.data.modifiedLease.find(v=>v.is_short_term != true) : ''
        let longTermDate = longTermData ? moment(longTermData.modification_date ? longTermData.modification_date : longTermData.commencement).format('YYYY-MM-DD') : !prop.data.is_short_term ? moment(prop.data.modification_date).format('YYYY-MM-DD') : moment(prop.data.terminal_end_date).add(1,'day').format('YYYY-MM-DD')
        const rouData = prop.data.rouAsset.filter(v => moment(v.date).isBefore(moment(longTermDate,'YYYY-MM-DD')))
        const liabilityData = prop.data.leaseLiability.filter(v => moment(v.date).isBefore(moment(longTermDate,'YYYY-MM-DD')))
        const rouLength = rouData && rouData.length
        const liaLength = liabilityData && liabilityData.length
        let data = []
        if(prop.data.rouAsset.length > prop.data.leaseLiability.length){
          for(const [index,rou] of rouData.entries()){
              data.push({ date:  moment.utc(rou.date).format('MMMM, YYYY'), 
                beginning: Number(Number(rou.beginning - (liabilityData && liabilityData[index] ? liabilityData[index].beginning : 0))).toFixed(2) != 0 ? prop.currency + " " + commaSeperatedNumber(rou.beginning - (liabilityData && liabilityData[index] ? liabilityData[index].beginning : 0)) : 0, 
                expense: Number(Number(-((liabilityData && liabilityData[index] ? liabilityData[index].interest : 0) + rou.amort))).toFixed(2) != 0 ? prop.currency + " " + commaSeperatedNumber(-((liabilityData && liabilityData[index] ? liabilityData[index].interest : 0) + rou.amort)) : 0, 
                payment: Number(Number(liabilityData && liabilityData[index] ? liabilityData[index].payment : 0)).toFixed(2) != 0 ? prop.currency + " " + commaSeperatedNumber(liabilityData && liabilityData[index] ? liabilityData[index].payment : 0) : 0, 
                transfer: Number(Number((index == (rouLength - 1)) ? -(rou.ending - (liabilityData && liabilityData[index] ? liabilityData[index].ending : 0)) : '')).toFixed(2) != 0 ? prop.currency + " " + commaSeperatedNumber(((index == (rouLength - 1)) ? -(rou.ending - (liabilityData && liabilityData[index] ? liabilityData[index].ending : 0)) : '')) : '' , 
              ending: Number(Number(rou.ending - (liabilityData && liabilityData[index] ? liabilityData[index].ending : 0)).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(rou.ending - (liabilityData && liabilityData[index] ? liabilityData[index].ending : 0)) : 0})
          }
        }else {
          for(const [index,liability] of liabilityData.entries()){
            data.push({ date:  moment.utc(liability.date).format('MMMM, YYYY'), 
              beginning: Number(Number((rouData && rouData[index] ? rouData[index].beginning : 0) - liability.beginning).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber((rouData && rouData[index] ? rouData[index].beginning : 0) - liability.beginning) : 0, 
              expense: Number(Number(-((rouData && rouData[index] ? rouData[index].amort : 0) + liability.interest)).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(-((rouData && rouData[index] ? rouData[index].amort : 0) + liability.interest)) : 0, 
              payment: Number(Number(liability.payment)).toFixed(2) != 0 ? prop.currency + " " + commaSeperatedNumber(liability.payment) : 0, 
              transfer: Number(Number((index == (liaLength - 1)) ? -((rouData && rouData[index] ? rouData[index].ending : 0 ) - liability.ending) : '').toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber((index == (liaLength - 1)) ? -((rouData && rouData[index] ? rouData[index].ending : 0 ) - liability.ending) : '') : '', 
              ending: Number(Number((index != (liaLength - 1)) ? ((rouData && rouData[index] ? rouData[index].ending : 0 ) - liability.ending ) : 0).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber((index != (liaLength - 1)) ? ((rouData && rouData[index] ? rouData[index].ending : 0 ) - liability.ending ) : 0) : 0
            })
          }
        }
        setInitialData(data)
        setOriginalData(data)
    }
  },[prop.data])

  const handleSearch = (value) => {
    const data = originalData.filter(v => v.date.match(new RegExp(value,"i")) || v.modification_adjustment.toString().match(new RegExp(value,"i")) || v.impairment.toString().match(new RegExp(value,"i")) || v.beginning.toString().match(new RegExp(value,"i")) || v.amort.toString().match(new RegExp(value,"i")) || v.ending.toString().match(new RegExp(value,"i")))
    setInitialData(data)
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
            MUIDataTableToolbar:{
              styleOverrides:{
                actions: {
                  display: 'contents'
                }
              }
            }
          }})}>
        <MUIDataTable
          title={
            <Row className="row">
              <Col className="search-p">
                <div className="ms-2 me-2 mb-3 mt-3">
                  <div className="position-relative sw">
                  <Input
                      placeholder="Search"
                      type="text"
                      value={search}
                      onChange={(e) => {handleSearch(e.target.value);setSearch(e.target.value)}}
                  />
                  </div>
                </div>
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
          }
          data={InitialData}
          columns={columns}
          options={{ 
            rowsPerPage:50000, //InitialData.length/10
            print:false, 
            download:true, 
            filter:false, 
            selectableRows:"none", 
            search:false, 
            rowsPerPageOptions:[], 
            responsive:'scroll',
            textLabels: {
              body: {
                noMatch: ""
              },
              viewColumns: {
                title: "",
              },
            },
            downloadOptions:{
              filename:'stbalance.csv'
            },
            rowHover: false,
            setRowProps: (row, dataIndex, rowIndex) => {
              return {
                style: { backgroundColor: rowIndex % 2 === 0 ? '#ffffff' : '#eeeeee'},
              };
            },
          }}
        />
      </ThemeProvider>
    </React.Fragment>
  )
}

export default STBalance;
