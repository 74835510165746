import React, { useState } from "react"
import { ButtonDropdown, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types';

const ButtonDropdownClient = (prop) => {
  const { setEditOpen, setDeleteOpen, setSelectedUser, user } = prop;
  const [viewBtn, setViewBtn] = useState(false);
  return (
    <div className="btn-group mb-2 dropstart">
      <ButtonDropdown
        isOpen={viewBtn}
        toggle={() => { setViewBtn(!viewBtn) }}
      >
        <DropdownToggle
          caret
          color="lbusers"
          className="btn bg-theme-color text-white btn-sm"
        >
          Actions &nbsp;{" "}
          <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu right>
            <DropdownItem tag={Link} to={{pathname:`/accounts/users/details`, search: user._id}}> View</DropdownItem>
            <DropdownItem onClick={() => { setEditOpen(true); setSelectedUser(user) }}> Edit</DropdownItem>
            <DropdownItem onClick={() => { setDeleteOpen(true); setSelectedUser(user) }}> Delete</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  )
}

ButtonDropdownClient.propTypes = {
  state: PropTypes.any,
  handleClick: PropTypes.any
}

export default ButtonDropdownClient